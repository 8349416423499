// BaseImage.js
import React from 'react';
import '../styles/BaseSelector.css';

function BaseSelector({ options, defaultOption, ...props }) {
    return (
        <select className="selector-base" {...props}>
            {defaultOption && (
                <option value={defaultOption.value} disabled={defaultOption.disabled} hidden={defaultOption.hidden}>
                    {defaultOption.label}
                </option>
            )}
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export { BaseSelector };
