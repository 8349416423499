import React from 'react';
import '../styles/BaseLoading.css';
import {RingLoader, ScaleLoader, PuffLoader} from 'react-spinners';

function BaseLoading(props) {

    return (
        <div className="column align-center full-size spinner-container">
            <PuffLoader color="#FFFFF0" size={40} />
        </div>
    );
}

function BaseLoadingInContainer({height, ...props}) {
    return (
        <div className="column align-center spinner-container-trans" style={{height: height, minHeight: 0}} {...props}>
            <RingLoader color="#000000" size={40} />
        </div>
    );
}

export { BaseLoading, BaseLoadingInContainer };

