import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./page/Main";
import React, {useState} from "react";
import SignIn from "./page/SignIn";


export const backendServer = "nhn-kt.nwc-hosting.com:8123"
export const AppContext = React.createContext();

function App() {
    const [profileImage, setProfileImage] = useState('');
    const [isDefaultImage, setIsDefaultImage] = useState(false);

  return (
      <Router>
          <AppContext.Provider value={{
              profileImage, setProfileImage,
              isDefaultImage, setIsDefaultImage
          }}>
              <Routes>
                  <Route path="/" element={
                      <Main />
                  } />
                  <Route path="/signin" element={
                      <SignIn />
                  } />
              </Routes>
          </AppContext.Provider>
      </Router>
  );
}

export default App;
