import React, { useContext, useEffect, useState } from 'react';
import '../styles/CCTV.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faClock,
    faFolder,
    faLocation, faSnowman, faUmbrella
} from "@fortawesome/free-solid-svg-icons";
import { faChartGantt } from "@fortawesome/pro-duotone-svg-icons";
import { GlobalContext } from "../page/Main";
import { VtoL, VtoP } from "../modules/extractLocation";
import HalfDonutChartWithPins from "../BaseComponents/HalfDonutChart";
import Sensor1HourChart from "../BaseComponents/Sensor1HourChart";
import { backendServer } from "../App";
import locationsData from "../modules/locations.json";

// 트리 아이템을 나타내는 컴포넌트
const TreeItem = ({ item, onToggle, isOpen, onClicked, focusLocation, subClicked }) => {
    const hasChildren = item.children && item.children.length > 0;

    // 트리 항목을 확장하거나 축소하는 함수
    const handleToggle = (locationValue, e) => {
        e.stopPropagation();
        if (locationValue) {
            subClicked(locationValue);
        }
        const path = item.path || item.name;
        onToggle(path);
    };

    // 항목을 클릭했을 때 처리하는 함수
    const handleClick = (value, e) => {
        if (!hasChildren && value !== null && value !== undefined) {
            e.stopPropagation();
            onClicked(value);
        }
    };

    return (
        <>
            <div className="row tree-item"
                 style={{alignItems: "center", backgroundColor: focusLocation === item.locationValue ? '#666' : ''}}
                 onClick={hasChildren ? handleToggle.bind(null, item.locationValue) : handleClick.bind(null, item.locationValue)}
            >
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                {hasChildren || item.isRoot ? (
                    <>
                        {item.isSub ? (
                            <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                        ) : (
                            <p className="bold" style={{margin: 0}}>{item.name}</p>
                        )}
                    </>
                ) : (
                    <>
                        {item.isSub ? (
                            <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                        ) : (
                            <p style={{fontSize: 10, margin: 0}}>{item.name}</p>
                        )}
                    </>
                )}

                {((hasChildren || item.isRoot)) && (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onClicked={onClicked}
                            focusLocation={focusLocation}
                            isOpen={isOpen}
                            subClicked={subClicked}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

// 메인 데이터 차트 컴포넌트
function DataChart() {
    const { focusLocation, setFocusLocation, weatherData } = useContext(GlobalContext);
    const [currentValues, setCurrentValues] = useState([]);
    const [weatherCount, setWeatherCount] = useState(0);
    const [dataCount, setDataCount] = useState(0);

    const cctvData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    children: [
                        {
                            childIcon: faLocation,
                            name: "KT(광주타워)",
                            locationValue: "101",
                        },
                    ],
                },
                { isRoot: true, name: "전라북도" },
                { isRoot: true, name: "충청북도" },
                { isRoot: true, name: "충청남도" },
                { isRoot: true, name: "경상북도" },
                { isRoot: true, name: "경상남도" },
                { isRoot: true, name: "강원특별자치도" },
                { isRoot: true, name: "경기도" },
                { isRoot: true, name: "제주특별자치도" },
            ]
        },
    ];

    // 선택된 로케이션에 대한 센서 데이터를 가져오는 함수
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://${backendServer}/get/sensorDatas`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        type: '1h',
                        locations_code: focusLocation,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setCurrentValues(result.groupedByCameraCode || []);
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        fetchData();
    }, [focusLocation]);

    // 데이터 및 날씨 카운트를 가져오는 함수
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://${backendServer}/get/sensorDatas/COUNT`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        locations_code: focusLocation,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setDataCount(result.count);
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        const fetchWeatherCount = async () => {
            try {
                const response = await fetch(`https://${backendServer}/getWeather/Count`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setWeatherCount(result.Count);
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        fetchData();
        fetchWeatherCount();
    }, [focusLocation]);

    // 트리 항목의 확장 상태를 초기화하는 함수
    const initializeOpenTreeItems = (data, path = '') => {
        const focusLocationPath = VtoP(focusLocation);
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            const isOpen = focusLocationPath && focusLocationPath.startsWith(currentPath);

            acc[currentPath] = isOpen;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(cctvData));

    // 트리 항목 클릭 처리
    const onElementClick = (locationValue) => {
        setFocusLocation(locationValue);
    };

    // 트리 항목 확장/축소 처리
    const toggleTreeItem = (path) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: !prevOpenTreeItems[path],
        }));
    };

    // 하위 트리 항목 클릭 처리
    const subClicked = (value) => {
        setFocusLocation(value);
    }

    // 트리 메뉴 컴포넌트
    const DataTree = React.memo(({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onClicked={(locationValue) => onElementClick(locationValue)}
                        focusLocation={focusLocation}
                        isOpen={openTreeItems}
                        subClicked={subClicked}
                    />
                ))}
            </div>
        );
    }, (prevProps, nextProps) => {
        return prevProps.data === nextProps.data;
    });

    // 기상 데이터를 가져오는 함수
    const getWeatherText = (type, category, hour = -1) => {
        let data;
        if (type === 0 && weatherData.today) {
            data = weatherData.today.filter(d => d.category === category);
        } else if (type === 1 && weatherData.yesterday) {
            data = weatherData.yesterday.filter(d => d.category === category);
        }

        if (hour !== -1 && data) {
            const specificHourData = data.find(d => d.fcstTime === `${String(hour).padStart(2, '0')}00`);
            if (specificHourData) {
                return specificHourData.fcstValue ? specificHourData.fcstValue : '0';
            }
        } else {
            if (data) {
                const sum = data.reduce((acc, curr) => {
                    const value = curr.fcstValue === '강수없음' || curr.fcstValue === '적설없음' ? 0 : parseInt(curr.fcstValue, 10);
                    return isNaN(value) ? acc : acc + value;
                }, 0);
                if (sum > 0) {
                    return sum.toString();
                }
            }
        }

        return '0';
    }

    const now = new Date();
    const currentHour = now.getHours();
    const totalNodeCount = locationsData.locations.reduce((acc, location) => acc + location.nodeCount, 0);

    return (
        <div className="row full-size" style={{position: "relative", overflow: "hidden"}}>
            {/* 데이터 차트 영역 */}
            <div className="column full-size" style={{width: "80%", position: "relative", overflow: "hidden"}}>
                <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                    <FontAwesomeIcon icon={faChartGantt} size="xs" style={{margin: 8}} />
                    <p className="bold" style={{width: "calc(80% + 32px)", margin: "16px 16px 16px 0", fontSize: 13, color: "white"}}>종합 데이터 보기  - {VtoL(focusLocation)}</p>
                </div>
                {/* 기온 관련 데이터 */}
                <div className="row" style={{background: "#464646", margin: "1rem", padding: "0.5rem"}}>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faUmbrella}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">기온</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>　</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>기온</p>
                            </div>

                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>최고</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap", background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMX')}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>최저</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMN')}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>평균</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{((parseFloat(getWeatherText(0, 'TMX')) + parseFloat(getWeatherText(0, 'TMN'))) / 2).toFixed(1)}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>단위</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>˚C</p>
                            </div>
                        </div>
                    </div>

                    {/* 시간대별 온도 */}
                    <div className="seperator-horz-half"></div>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faUmbrella}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">시간대별 온도</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            {/* 시간별 기온 표시 */}
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMP', currentHour)}˚C</p>
                            </div>
                            {/* 이후 시간대별 기온 표시 */}
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 1).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMP', currentHour + 1)}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 2).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMP', currentHour + 2)}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 3).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMP', currentHour + 3)}˚C</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 4).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'TMP', currentHour + 4)}˚C</p>
                            </div>
                        </div>
                    </div>

                    {/* 시간대별 습도 */}
                    <div className="seperator-horz-half"></div>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faSnowman}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">시간대별 습도</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            {/* 시간별 습도 표시 */}
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{
                                    background: "#525252",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 12
                                }}>{String(currentHour).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{
                                    whiteSpace: "nowrap",
                                    background: "#8c8c8c",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 10
                                }}>{getWeatherText(0, 'REH', currentHour)}%</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{
                                    background: "#525252",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 12
                                }}>{String(currentHour + 1).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{
                                    whiteSpace: "nowrap",
                                    background: "#8c8c8c",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 10
                                }}>{getWeatherText(0, 'REH', currentHour + 1)}%</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{
                                    background: "#525252",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 12
                                }}>{String(currentHour + 2).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{
                                    whiteSpace: "nowrap",
                                    background: "#8c8c8c",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 10
                                }}>{getWeatherText(0, 'REH', currentHour + 2)}%</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{
                                    background: "#525252",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 12
                                }}>{String(currentHour + 3).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{
                                    whiteSpace: "nowrap",
                                    background: "#8c8c8c",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 10
                                }}>{getWeatherText(0, 'REH', currentHour + 3)}%</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{
                                    background: "#525252",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 12
                                }}>{String(currentHour + 4).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{
                                    whiteSpace: "nowrap",
                                    background: "#8c8c8c",
                                    border: "1px solid #222222",
                                    padding: "0.4rem",
                                    margin: 0,
                                    fontSize: 10
                                }}>{getWeatherText(0, 'REH', currentHour + 4)}%</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 최근 카메라 데이터 섹션 */}
                <p className="bold" style={{
                    width: "calc(80% + 32px)",
                    marginLeft: 16,
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: 14,
                    color: "white"
                }}>카메라 데이터(최근 1개)</p>

                <div className="column full-size-width" style={{position: "relative"}}>
                    <div className="row align-center"
                         style={{maxWidth: "100%", position: "relative", overflowX: 'auto', overflowY: 'hidden'}}>
                        {
                            Object.entries(currentValues).map(([cameraCode, sensorDatas]) => {
                                const sensorDatasWithOffset = sensorDatas.map(sensorData => ({
                                    temp_max: sensorData.temp_max,
                                    temp_min: sensorData.temp_min,
                                    temp_avg: sensorData.temp_avg,
                                    dateStr: sensorData.create_at,
                                }));

                                return (
                                    <HalfDonutChartWithPins
                                        key={cameraCode}
                                        setupValue={100}
                                        sensorDatas={sensorDatasWithOffset}
                                        sensorNo={cameraCode}
                                    />
                                );
                            })
                        }
                    </div>

                    {/* 센서 1시간 데이터 차트 */}
                    <Sensor1HourChart setupValue={100} cameraData={{currentValues}}/>

                    {/* 데이터 카운트 및 정보 표시 */}
                    <div className="row">
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width"
                             style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>총 카메라 데이터</p>
                            <p className="text-align-right full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{dataCount} 개</p>
                        </div>
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width"
                             style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>기상청 연계 데이터</p>
                            <p className="text-align-right full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{weatherCount} 건</p>
                        </div>
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width"
                             style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>총 카메라</p>
                            <p className="text-align-right full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{totalNodeCount} 개</p>
                        </div>
                        <div className="seperator-horz-half"/>
                    </div>
                </div>
            </div>

            {/* CCTV 트리 메뉴 */}
            <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                <div className="row full-size-width" style={{
                    height: "3%",
                    position: "relative",
                    alignItems: "center",
                    borderBottom: "1px solid #ffffff50"
                }}>
                    <p className="bold" style={{width: "20%", marginLeft: 16, fontSize: 13, color: "white"}}>지역별</p>
                </div>
                <DataTree data={cctvData}/>
            </div>
        </div>
    );
}

export default DataChart;
