import React, {useState, useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko'; // 한국어 로케일

import './lib/css/calendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-duotone-svg-icons";

moment.locale('ko'); // 전역 로케일 설정
const localizer = momentLocalizer(moment);

const Event = ({ event }) => {
    let backgroundColor = '#3174ad'; // 기본 색상
    if (event.title === "주의") {
        backgroundColor = '#f08c4e'; // 검색된 데이터의 배경색
    } else if (event.title === "경계") {
        backgroundColor = '#d5b340'; // 사람 수의 배경색
    } else if (event.title === "위험") {
        backgroundColor = '#d9534f'; // 차량 수의 배경색
    }

    return (
        <>
            {(event.resource.count !== undefined) ? (
                <>
                    <div style={{
                        backgroundColor,
                        margin: '2px 0',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        color: 'white'
                    }}>
                        <div>{event.resource.count}</div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </>
    );
};

const MyCalendar = ({events}) => {
    const [date, setDate] = useState(
        events && events.length > 0 ? new Date(events[0].end) : new Date()
    );

    const [year, setYear] = useState(date.getFullYear());
    const [month, setMonth] = useState(date.getMonth() + 1);

    useEffect(() => {
        setYear(date.getFullYear());
        setMonth(date.getMonth() + 1);
    }, [date]);

    const handlePrev = () => {
        const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        setDate(prevMonth);
    };

    const handleNext = () => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        setDate(nextMonth);
    };

    const monthNames = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

    return (
        <div className="column"
             style={{background: "white", margin: "1rem", borderRadius: "0.5rem", padding: "1rem 0.5rem"}}>
            <div className="row full-size-width align-center">
                <button onClick={handlePrev} style={{border: "1px solid #cbcbcb", background: "transparent", borderRadius: 5}}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </button>
                <div className="column align-center">
                    <p style={{margin: "0 0.5rem", padding: 0, color: "#333333", fontSize: 10}}>{year}</p>
                    <p style={{
                        margin: "0 0.5rem",
                        padding: 0,
                        color: "black",
                        fontSize: 16
                    }}>{monthNames[month - 1]}</p>
                </div>

                <button onClick={handleNext}
                        style={{border: "1px solid #cbcbcb", background: "transparent", borderRadius: 5}}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>
            </div>
            <div className="seperator-half-top"/>
            <div className="row full-size-width">
                <div className="row full-size-width align-center">
                    <FontAwesomeIcon icon={faCircle} fontSize={6} color={"#f08c4e"} style={{margin: "0 0.5rem"}}/>
                    <p style={{margin: 0, padding: 0, color: "black", fontSize: 12}}>주의</p>
                </div>
                <div className="row full-size-width align-center">
                    <FontAwesomeIcon icon={faCircle} fontSize={6} color={"#d5b340"} style={{margin: "0 0.5rem"}}/>
                    <p style={{margin: 0, padding: 0, color: "black", fontSize: 12}}>경계</p>
                </div>
                <div className="row full-size-width align-center">
                    <FontAwesomeIcon icon={faCircle} fontSize={6} color={"#d9534f"} style={{margin: "0 0.5rem"}}/>
                    <p style={{margin: 0, padding: 0, color: "black", fontSize: 12}}>위험</p>
                </div>
            </div>
            <div className="seperator-half-top"/>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                date={date}
                onNavigate={setDate}
                style={{height: 600}}
                defaultView="month"
                views={["month"]}
                messages={{
                    today: "오늘",
                    next: "다음",
                    previous: "이전",
                    month: "월",
                    week: "주",
                    day: "일"
                }}
                components={{
                    event: Event,
                    toolbar: () => null,
                }}
            />
        </div>
    );
};

export default MyCalendar;