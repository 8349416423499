import {Box, Slider} from "@mui/material";
import React from "react";

const marks = [
    {
        value: 0,
        label: '',
    },
    {
        value: 1,
        label: '',
    }
];

function valuetext(value) {
    const str =  value === 0 ? "OFF" : "ON";
    return str;
}

function valueLabelFormat(value) {
    const str =  marks.findIndex((mark) => mark.value === value) === 0 ? "OFF" : "ON";
    return str;
}

function OnOffSlider({value, handleChange}) {

    return (
        <Box sx={{ width: 60 }}>
            <Slider
                value={value}
                aria-label="alertSet"
                getAriaValueText={valuetext}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                shiftStep={1}
                step={1}
                marks
                min={0}
                max={1}
                marks={marks}
                onChange={(e) => {
                    handleChange(e.target.value)
                }}

                sx={{
                    height: 8,
                    color: value === 0 ? '#a9a9a9' : '#4b8cd3'
                }}
            />
        </Box>
    )
}

export default OnOffSlider;