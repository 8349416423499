import React, {useEffect, useState} from "react";

const Needle = ({ rotation = 0, color = "black", length = 60, top = "0", left = "0", transform = "" }) => {
    const [currentRotation, setCurrentRotation] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setCurrentRotation(rotation);  // 약간의 지연 후 원하는 rotation 값으로 변경
        }, 100);  // 100ms 후에 실행, 필요에 따라 조정 가능
    }, [rotation]);

    return (
        <svg width="4" height={length} viewBox={`0 0 4 ${length}`} style={{
            position: "absolute",
            top: top,
            left: left,
            transformOrigin: "center bottom",
            transform:  `${transform} rotate(${currentRotation}deg)`,
            transition: "transform 1.5s ease-in-out"
        }}>
            <path d={`M2 0 L0 ${length} L4 ${length} Z`} fill={color}/>
        </svg>
    );
}

export default Needle;
