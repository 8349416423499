import React, {useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/MobileCardSlider.css';

function MobileCardSlider({onSlideChange, children}) {
    const [isSliding, setIsSliding] = useState(false);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: () => setIsSliding(true),
        afterChange: (current) => {
            setIsSliding(false)
            if(onSlideChange){
                onSlideChange(current);
            }
        },
    };

    const handleClick = (e) => {
        if (isSliding) {  // 슬라이드 동작 중일 때 클릭 이벤트 방지
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <div style={{width: "100%"}} onClick={handleClick}>
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    );
}

export default MobileCardSlider;
