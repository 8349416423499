import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/pro-duotone-svg-icons";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {ITEM_SIZE, TableRow, widthPercentage} from "../tablerows/userList_TableRow";
import {FixedSizeList as List} from "react-window";

const UserListModal = ({handleClose = () => {}, userList}) => {

    const [filteredUserList, setFilteredUserList] = useState(userList);

    const [ values, setValues ] = useState({
        search_phone: "",
        search_name: "",
        search_id: "",
    })

    useEffect(() => {
        setFilteredUserList(userList.filter(user =>
            (user.user_phone.includes(values.search_phone)) &&
            (user.user_name.includes(values.search_name)) &&
            (user.user_id.includes(values.search_id))
        ));
    }, [values, userList]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    return (
        <div className="column align-center" style={{
            background: "white",
            width: 600,
            height: 480,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: "0.5rem",
            padding: "1rem",
        }}>
            <button className="modal-close" onClick={handleClose} style={{
                position: "absolute",
                top: 16,
                right: 16,
                width: 32,
                height: 32,
                border: "1px solid #cbcbcb",
                borderRadius: 5,
                cursor: "pointer",
                zIndex: 1000
            }}>
                <FontAwesomeIcon icon={faTimes}/>
            </button>
            <div className="row full-size-width">
                <p className="full-size-width"
                   style={{
                       whiteSpace: "nowrap",
                       color: "black",
                       fontSize: 14,
                       margin: "0.5rem 0",
                       padding: 0,
                       textAlign: "center",
                       fontWeight: "bold"
                   }}>유저 목록</p>
            </div>
            <div className="seperator-half"/>
            <div className="row" style={{
                width: 500,
            }}>
                <div className="column full-size-width">
                    <p className="full-size-width"
                       style={{
                           whiteSpace: "nowrap",
                           color: "#707070",
                           fontSize: 10,
                           margin: 0,
                           padding: 0,
                       }}>아이디 검색</p>
                    <div
                        className="row input-container align-center full-size-width"
                        style={{
                            background: "#b6b6b6",
                            borderRadius: "4px",
                            border: "1px solid #ffffffb0",
                            margin: "0.5rem 0"
                        }}>
                        <FontAwesomeIcon
                            style={{color: "#707070", marginLeft: "1rem"}}
                            fontSize={12} icon={faPhone}/>
                        <input
                            style={{
                                padding: "0.5rem",
                                fontSize: 12,
                                color: "black",
                            }}
                            autoComplete={"off"} name="search_id"
                            onChange={handleChange}
                            value={values.search_id} placeholder={"아이디"}
                            className="full-size-width"/>
                    </div>
                </div>
                <div className="seperator-horz-half"/>
                <div className="column full-size-width">
                    <p className="full-size-width"
                       style={{
                           whiteSpace: "nowrap",
                           color: "#707070",
                           fontSize: 10,
                           margin: 0,
                           padding: 0,
                       }}>이름 검색</p>
                    <div
                        className="row input-container align-center full-size-width"
                        style={{
                            background: "#b6b6b6",
                            borderRadius: "4px",
                            border: "1px solid #ffffffb0",
                            margin: "0.5rem 0"
                        }}>
                        <FontAwesomeIcon
                            style={{color: "#707070", marginLeft: "1rem"}}
                            fontSize={12} icon={faPhone}/>
                        <input
                            style={{
                                padding: "0.5rem",
                                fontSize: 12,
                                color: "black",
                            }}
                            autoComplete={"off"} name="search_name"
                            onChange={handleChange}
                            value={values.search_name} placeholder={"이름"}
                            className="full-size-width"/>
                    </div>
                </div>
                <div className="seperator-horz-half"/>
                <div className="column full-size-width">
                    <p className="full-size-width"
                       style={{
                           whiteSpace: "nowrap",
                           color: "#707070",
                           fontSize: 10,
                           margin: 0,
                           padding: 0,
                       }}>전화번호 검색</p>
                    <div
                        className="row input-container align-center full-size-width"
                        style={{
                            background: "#b6b6b6",
                            borderRadius: "4px",
                            border: "1px solid #ffffffb0",
                            margin: "0.5rem 0"
                        }}>
                        <FontAwesomeIcon
                            style={{color: "#707070", marginLeft: "1rem"}}
                            fontSize={12} icon={faPhone}/>
                        <input
                            style={{
                                padding: "0.5rem",
                                fontSize: 12,
                                color: "black",
                            }}
                            autoComplete={"off"} name="search_phone"
                            onChange={handleChange}
                            value={values.search_phone} placeholder={"전화번호"}
                            className="full-size-width"/>
                    </div>
                </div>
            </div>
            <div className="seperator-half-top"/>
            <div className="column" style={{
                width: 500,
            }}>
                <div className="row full-size-width"
                     style={{
                         height: 24,
                         color: "black",
                         fontSize: 13,
                         border: "1px solid #00000080",
                         overflowY: userList.length >= 14 ? "scroll" : "hidden",
                     }}
                >
                    <div className="column align-center"
                         style={{
                             flex: widthPercentage[1],
                             backgroundColor: '#ffffff',
                             overflow: 'hidden',
                             textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap',
                             borderRight: "1px solid #00000080"
                         }}>
                        <p className="bold text-align-center">아이디</p>
                    </div>
                    <div className="column align-center"
                         style={{
                             flex: widthPercentage[1],
                             backgroundColor: '#ffffff',
                             overflow: 'hidden',
                             textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap',
                             borderRight: "1px solid #00000080"
                         }}>
                    <p className="bold text-align-center">이름</p>
                    </div>
                    <div className="column align-center"
                         style={{
                             flex: widthPercentage[4],
                             backgroundColor: '#ffffff',
                             overflow: 'hidden',
                             textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap'
                         }}>
                        <p className="bold text-align-center">연락처</p>
                    </div>
                </div>
                <List
                    width={502}
                    height={332}
                    className="table full-size-width"
                    itemCount={filteredUserList.length}
                    itemSize={ITEM_SIZE}
                    itemData={filteredUserList}
                >
                    {TableRow}
                </List>
            </div>
        </div>
    );
};

export default UserListModal;