import React, { useContext, useState } from 'react';

import '../styles/CCTV.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faChevronDown,
    faChevronUp,
    faFolder,
    faLocation
} from "@fortawesome/free-solid-svg-icons";
import { faCameraCctv } from "@fortawesome/pro-duotone-svg-icons";
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import { GlobalContext } from "../page/Main";
import { VtoL, VtoP } from "../modules/extractLocation";
import locationsData from '../modules/locations.json';
import { backendServer } from "../App";
import { BaseButton } from "../BaseComponents/BaseButton";

// TreeItem 컴포넌트: 트리 구조의 항목을 나타냄
const TreeItem = ({ item, onToggle, isOpen, onClicked, selectedImage, subClicked }) => {
    const hasChildren = item.children && item.children.length > 0;

    // 트리 항목을 확장/축소하는 함수
    const handleToggle = (locationValue, e) => {
        e.stopPropagation(); // 상위 이벤트로 전파되지 않게 방지

        if (locationValue) {
            subClicked(locationValue); // 서브 항목 클릭 시 호출
        }
        const path = item.path || item.name; // 고유 경로가 없으면 이름을 사용
        onToggle(path, locationValue);
    };

    // 트리 항목 클릭 시 호출되는 함수
    const handleClick = (value, locationValue, e) => {
        if (!hasChildren && value !== null && value !== undefined) {
            e.stopPropagation(); // 상위 이벤트로 전파 방지
            onClicked(value, locationValue); // 클릭된 항목에 대한 처리
        }
    };

    return (
        <>
            <div className="row tree-item"
                 style={{ alignItems: "center", backgroundColor: selectedImage === item.value ? '#666' : '' }}
                 onClick={hasChildren ? handleToggle.bind(null, item.locationValue) : handleClick.bind(null, item.value, item.locationValue)}
            >
                {/* 항목에 따라 폴더 또는 카메라 아이콘을 표시 */}
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{ margin: "0 4px 0 0" }} size='2xs' icon={faFolder} />
                ) : (
                    <FontAwesomeIcon style={{ margin: "0 4px" }} size='2xs' icon={item.childIcon} />
                )}

                {/* 항목 이름 표시 */}
                {hasChildren || item.isRoot ? (
                    <>
                        {item.isSub ? (
                            <p style={{ fontSize: 12, margin: 0 }}>{item.name}</p>
                        ) : (
                            <p className="bold" style={{ margin: 0 }}>{item.name}</p>
                        )}
                    </>
                ) : (
                    <>
                        {item.isSub ? (
                            <p style={{ fontSize: 12, margin: 0 }}>{item.name}</p>
                        ) : (
                            <p style={{ fontSize: 10, margin: 0 }}>{item.name}</p>
                        )}
                    </>
                )}

                {/* 자식 항목이 있으면 펼치기/접기 버튼 표시 */}
                {(hasChildren || item.isRoot) && (
                    <FontAwesomeIcon style={{ margin: "0 0 0 16px" }} size='2xs' icon={isOpen ? faChevronDown : faChevronUp} />
                )}
            </div>
            {/* 자식 항목이 있는 경우 트리 항목을 표시 */}
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{ overflow: 'hidden', transition: 'max-height 0.3s ease-in-out' }}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onClicked={onClicked}
                            selectedImage={selectedImage}
                            isOpen={isOpen}
                            subClicked={subClicked}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

function CCTV() {
    const { focusLocation, setFocusLocation } = useContext(GlobalContext); // 전역 컨텍스트에서 위치 정보 가져옴
    const [selectedImage, setSelectedImage] = useState(null); // 선택된 이미지 상태 관리

    // focusLocation에 해당하는 로케이션 정보 찾기
    const currentLocation = locationsData.locations.find(location => location.code === focusLocation);

    // 카메라 노드를 2개씩 그룹화
    const cameraGroups = currentLocation?.cameraNodes ? currentLocation.cameraNodes.reduce((result, value, index, array) => {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
    }, []) : [];

    // 트리 구조 데이터
    const cctvData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    children: [
                        {
                            isSub: true,
                            name: "KT광주타워",
                            locationValue: "101",
                            children: [
                                { childIcon: faCamera, name: "CCTV1", locationValue: "101", value: 1 },
                                { childIcon: faCamera, name: "CCTV2", locationValue: "101", value: 2 }
                            ],
                        },
                    ],
                },
                { isRoot: true, name: "전라북도" },
                { isRoot: true, name: "충청북도" },
                { isRoot: true, name: "충청남도" },
                { isRoot: true, name: "경상북도" },
                { isRoot: true, name: "경상남도" },
                { isRoot: true, name: "강원특별자치도" },
                { isRoot: true, name: "경기도" },
                { isRoot: true, name: "제주특별자치도" },
            ]
        },
    ];

    // 트리 항목을 열기 위한 초기화 함수
    const initializeOpenTreeItems = (data, path = '') => {
        const focusLocationPath = VtoP(focusLocation);
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            const isOpen = focusLocationPath && focusLocationPath.startsWith(currentPath);

            acc[currentPath] = isOpen;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(cctvData));

    // 이미지 클릭 시 호출
    const onImageClick = (id, locationValue) => {
        if (locationValue !== focusLocation) {
            setFocusLocation(locationValue);
        }

        if (id === selectedImage) {
            setSelectedImage(null);
        } else {
            setSelectedImage(id);
        }
    };

    // 트리 항목 토글 함수
    const toggleTreeItem = (path, locationValue) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: prevOpenTreeItems[path] && (locationValue !== focusLocation) ? prevOpenTreeItems[path] : !prevOpenTreeItems[path],
        }));
    };

    const subClicked = (value) => {
        setFocusLocation(value);
    };

    // CCTV 트리 컴포넌트
    const CCTVTree = React.memo(({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onClicked={(index, locationValue) => onImageClick(index, locationValue)}
                        selectedImage={selectedImage}
                        isOpen={openTreeItems}
                        subClicked={subClicked}
                    />
                ))}
            </div>
        );
    }, (prevProps, nextProps) => {
        return prevProps.data === nextProps.data;
    });

    return (
        <div className="column full-size" style={{ position: "relative", overflow: "hidden" }}>
            {/* 상단 정보 표시 */}
            <div className="row full-size-width" style={{ height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50" }}>
                <FontAwesomeIcon icon={faCameraCctv} size="xs" style={{ margin: 8 }} />
                <p className="bold" style={{ width: "calc(80% + 32px)", margin: "16px 16px 16px 0", fontSize: 13, color: "white" }}>실시간 현황 모니터  - {VtoL(focusLocation)}</p>
                <p className="bold" style={{ width: "20%", marginLeft: 16, fontSize: 13, color: "white" }}>CCTV</p>
            </div>
            <div className="row full-size-width" style={{ height: "auto", position: "relative" }}>
                {/* CCTV 화면 영역 */}
                <div className="column full-size" style={{ width: "80%", height: "auto", position: "relative", overflow: 'hidden' }}>
                    {selectedImage ? (
                        <>
                            {/* 선택된 이미지 표시 */}
                            <div className={`image-container`} style={{ width: "100%", height: "100%" }} onClick={() => onImageClick(selectedImage, focusLocation)}>
                                <RTSPPlayer sleep={0} key={`stream0`} streamUrl={`https://${backendServer}/live/${focusLocation}/${'00' + (selectedImage)}/stream.m3u8`} />
                                {((selectedImage) === 3 || (selectedImage) === 4) ? (
                                    <BaseButton onClick={(e) => { e.stopPropagation(); setSelectedImage(selectedImage - 2) }} style={{ position: "absolute", bottom: "2rem", right: "1.5rem", fontSize: 16, padding: "1rem" }}>일반화면 전환</BaseButton>
                                ) : (
                                    <BaseButton onClick={(e) => { e.stopPropagation(); setSelectedImage(selectedImage + 2) }} style={{ position: "absolute", bottom: "2rem", right: "1.5rem", fontSize: 16, padding: "1rem" }}>열상화면 전환</BaseButton>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {/* 기본 CCTV 화면 표시 */}
                            <div className="row align-center" style={{ height: 'auto', position: "relative", overflow: 'hidden' }}>
                                <div className="column align-center image-container" onClick={() => onImageClick(1, focusLocation)}>
                                    <p style={{ width: "100%", boxSizing: "border-box", padding: "0.5rem", margin: 0, fontSize: 14, zIndex: 11 }}>CAM1</p>
                                    <RTSPPlayer sleep={0} streamUrl={`https://${backendServer}/live/${focusLocation}/001/stream.m3u8`} />
                                </div>
                                <div className="column align-center image-container" onClick={() => onImageClick(3, focusLocation)}>
                                    <p style={{ width: "100%", boxSizing: "border-box", padding: "0.5rem", margin: 0, fontSize: 14, zIndex: 11 }}>CAM1-열상</p>
                                    <RTSPPlayer sleep={0} streamUrl={`https://${backendServer}/live/${focusLocation}/003/stream.m3u8`} />
                                </div>
                            </div>
                            <div className="row align-center" style={{ height: 'auto', position: "relative", overflow: 'hidden' }}>
                                <div className="column align-center image-container" onClick={() => onImageClick(2, focusLocation)}>
                                    <p style={{ width: "100%", boxSizing: "border-box", padding: "0.5rem", margin: 0, fontSize: 14, zIndex: 11 }}>CAM2</p>
                                    <RTSPPlayer sleep={0} streamUrl={`https://${backendServer}/live/${focusLocation}/002/stream.m3u8`} />
                                </div>
                                <div className="column align-center image-container" onClick={() => onImageClick(4, focusLocation)}>
                                    <p style={{ width: "100%", boxSizing: "border-box", padding: "0.5rem", margin: 0, fontSize: 14, zIndex: 11 }}>CAM2-열상</p>
                                    <RTSPPlayer sleep={0} streamUrl={`https://${backendServer}/live/${focusLocation}/004/stream.m3u8`} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {/* CCTV 트리 표시 */}
                <div className="column full-size" style={{ width: "20%", position: "relative", overflow: 'hidden' }}>
                    <CCTVTree data={cctvData} />
                </div>
            </div>
        </div>
    );
}

export default CCTV;
