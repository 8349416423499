import locationsData from './locations.json';

function VtoL(value) {
    const location = locationsData.locations.find(loc => loc.code === value);
    return location ? location.name : "알 수 없음";
}

function VtoP(value) {
    const location = locationsData.locations.find(loc => loc.code === value);
    return location ? location.fullPath : "알 수 없음";
}
export { VtoL, VtoP };
