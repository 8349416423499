// Input.js
import React from 'react';
import '../styles/BaseInput.css';

function BaseInput(props) {
    return (
        <input className="input-base" {...props} />
    );
}

export default BaseInput;
