import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation, faExclamationCircle, faLightbulb} from "@fortawesome/pro-regular-svg-icons";

const ITEM_SIZE = 26;
const widthPercentage = ['50px', '100px','150px','200px','250px','300px', '350px', '400px','450px','500px'];

function getStyle(widthPercentage, isFirst, index = 2) {
    if(isFirst){
        return {
            flex: widthPercentage,
            backgroundColor: index % 2 === 1 ? '#646464' : '#a2a2a2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' };
    }else{
        return {
            flex: widthPercentage,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' };
    }
}

function TableRow({ index, style, data }) {
    const rowData = data[index];

    const rowStyle = {
        ...style,
        top: ITEM_SIZE * index,
    };

    const headerStyle = {
        height: '20px'
    };

    const bodyStyle = {
        backgroundColor: index % 2 === 1 ? '#494949' : '#7a7a7a',
    }

    return (
        <>
            <div style={rowStyle}>
                <div className="row full-size-width table-item" style={bodyStyle}>
                    <div className="column align-center"
                         style={{...getStyle(widthPercentage[0], false, index), borderRight: "1px solid #2B2B2B"}}>
                        <FontAwesomeIcon size={'1x'} color={rowData.type === 0 ? 'palegoldenrod' : "indianred"}
                                         icon={rowData.type === 0 ? faLightbulb : faExclamationCircle}/>
                    </div>
                    <div className="column align-center" style={{...getStyle(widthPercentage[2], false, index), borderRight: "1px solid #2B2B2B"}}>
                        <p style={{fontSize: 10}}>{rowData.msg}</p>
                    </div>
                    <div className="column align-center" style={getStyle(widthPercentage[7], false, index)}>
                        <p style={{fontSize: 10}}>{rowData.time}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export {ITEM_SIZE, TableRow, widthPercentage, getStyle};
