import React, {useEffect, useState} from 'react';
import {
    PieChart,
    Pie,
    Cell,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    LabelList,
    ReferenceLine
} from 'recharts';
import Needle from "./Needle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarChart,
    faBolt, faCheckCircle, faClock,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle, faTimesCircle, faTint, faWater
} from "@fortawesome/free-solid-svg-icons";
import {faTemperatureHalf} from "@fortawesome/pro-regular-svg-icons";

const data = [
    { name: 'Group A', value: 0.3 },
    { name: 'Group B', value: 0.25 },
    { name: 'Group C', value: 0.25 },
    { name: 'Group D', value: 0.2 }
];

const COLORS = ['rgba(60, 179, 113, 0.8)', 'rgba(255, 165, 0, 0.8)', 'rgba(255, 215, 0, 0.8)', 'rgba(204, 0, 0, 0.8)'];

const HalfDonutChartWithPins = ({setupValue, sensorDatas, sensorNo}) => {
    const radius = 80; // 핀의 위치를 조정하기 위해 조금 수정되었습니다.
    const center = { x: 81, y: 93 };

    // sensor_1과 sensor_2의 값을 setupValue를 넘지 않도록 조정
    const latestSensorData = sensorDatas[0]; // 가장 최근 데이터 가정
    const latestSensor1 = latestSensorData.temp_avg.toFixed(1);
    const latestDateStr = latestSensorData ? latestSensorData.dateStr : 0;
    const adjustedSensor1 = Math.min(latestSensor1, setupValue);

    // 차트에 표시할 데이터
    const referenceData = [
        {
            name: '최고',
            value: latestSensorData.temp_max.toFixed(1),
            originalValue: latestSensorData.temp_max.toFixed(1) // 임계값을 데이터에 포함
        },
        {
            name: '최저',
            value: latestSensorData.temp_min.toFixed(1),
            originalValue: latestSensorData.temp_min.toFixed(1) // 임계값을 데이터에 포함
        }
    ];

    const calculatePosition = (angle, distance) => {
        const radian = (angle * Math.PI) / 180;
        return {
            x: center.x + distance * Math.cos(radian),
            y: center.y + distance * Math.sin(radian)
        };
    };

    const formatDate = (dateStr) => {
        const dateStrAdjusted = dateStr.replace('Z', '');

        const now = new Date(dateStrAdjusted);
        const year = now.getFullYear().toString(); // 년도의 마지막 두 자리
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
        const date = now.getDate().toString().padStart(2, '0'); // 일
        const hours = now.getHours().toString().padStart(2, '0'); // 시
        const minutes = now.getMinutes().toString().padStart(2, '0'); // 분

        return `${year}-${month}-${date} ${hours}:${minutes}`;
    }

    const getLabel = () => {
        const ratio1 = (latestSensor1 / setupValue) * 100;

        let errorRate = 0;

        let level = 0;
        if (ratio1 >= 80) {
            level += 4;
        } else if (ratio1 >= 50 && ratio1 < 80) {
            level += 3;
        } else if (ratio1 >= 30 && ratio1 < 50) {
            level += 2;
        } else {
            level += 1;
        }

        if (level >= 4) {
            return { message: "위험", color: 'rgba(204, 0, 0, 0.8)', icon: faExclamationTriangle, errorRate: errorRate }; // 더 채도 높은 붉은색 톤
        } else if (level >= 3 && level < 4) {
            return { message: "주의", color: 'rgba(255, 215, 0, 0.8)', icon: faExclamationCircle, errorRate: errorRate }; // 더 채도 높고 노란색에 가까운 주황색 톤
        } else if (level >= 2 && level < 3) {
            return { message: "경계", color: 'rgba(255, 165, 0, 0.8)', icon: faExclamation, errorRate: errorRate }; // 더 채도 높은 주황색 톤
        } else {
            return { message: "안전", color: 'rgba(60, 179, 113, 0.8)', icon: faCheckCircle, errorRate: errorRate }; // 더 채도 높은 연한 녹색 톤
        }
    }
    
    const getPersent = () => {
        const ratio = ((latestSensor1) / setupValue) * 100;
        return Math.min((Math.floor(ratio * 10) / 10), 100);
    }

    const label = getLabel();
    let datas = { low: 0, high: setupValue };
    const step = [0, setupValue * (30 / 100), setupValue * (50 / 100), setupValue * (80 / 100), setupValue] // 10개의 값을 생성하기 위한 간격

    const minimun = 0;
    const maximun = setupValue;

    const values = Array.from({ length: 5 }, (_, index) => {
        return Math.round(datas.low + step[index]);
    });


    const posStep = [0, 180 * 0.30, 180 * 0.50, 180 * 0.80, 180];
    const positions = Array.from({ length: 5 }, (_, index) => {
        return calculatePosition(Math.round(180 + (posStep[index])), radius);
    });

    const getRotationFromValue = (value, min = minimun, max = maximun) => {
        if (value <= min) return 270;
        if (value >= max) return 90;
        const ratio = (value - min) / (max - min);
        return 270 + (ratio * 180);
    }

    const getFillColor = (name) => {
        if (name === '평균') return 'rgba(0, 128, 255, 0.5)';
        if (name === '최고') return 'rgba(225,59,59,0.5)';
        if (name === '최저') return 'rgba(60, 179, 113, 0.8)';
        return 'transparent'; // 임계값의 경우
    };

    function renderCustomLabel(props) {
        const { x, y, width, value, viewBox } = props;
        const centerY = y + (viewBox.height / 2);
        const centerX = x + (width) - 5;

        return (
            <text x={centerX} y={centerY} fill={value === 0 ? 'transparent' : '#ffffff'} textAnchor="end" dominantBaseline="middle" fontSize="8">
                {value}
            </text>
        );
    }

    return (
        <div className="column grid-container">
            <div className="column grid-form" style={{width: 240, position: "relative", margin: "0.5rem"}}>
                <div className="row grid-title">
                    <FontAwesomeIcon icon={faBarChart}/>
                    <div className="seperator-horz-half"></div>
                    <p className="full-size bold">{parseInt(sensorNo)}번 카메라</p>
                    <div className="row align-center">
                        <FontAwesomeIcon icon={faTemperatureHalf}/>
                        <p className="bold" style={{fontSize: 12, margin: '0 4px'}}>˚C</p>
                    </div>
                </div>
                <div className="row grow-size align-center" style={{background: "#444444", boxSizing: "border-box"}}>
                    <div className="row full-size align-center" style={{position: "relative"}}>
                        <div style={{ position: "relative", width: 160, height: 130 }}>
                            <PieChart width={160} height={100}>
                                <Pie
                                    data={data}
                                    cx={75}
                                    cy={95}
                                    startAngle={180}
                                    endAngle={0}
                                    innerRadius={60}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`${Math.random()}-cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            {values.map((value, index) => {
                                return (
                                    <div style={{color: index !== 0 ? COLORS[index - 1] : 'white', fontWeight: "bold", fontSize: "10px", position: "absolute", top: `${positions[index].y}px`, left: `${positions[index].x}px`, transform: "translate(-50%, 0)" }}>
                                        {value}
                                    </div>
                                );
                            })}
                            <Needle top={`${center.y - 45}px`} left={`${center.x}px`} length={50} rotation={getRotationFromValue(latestSensor1)} color={"rgba(0, 128, 255, 0.8)"}/>
                            <p style={{position: "absolute", color: label.color, fontSize: "15px", fontWeight: "bold", bottom: -5, left: "50%", transform: "translate(-50%, 0)"}}>{label.message}</p>
                        </div>
                    </div>
                </div>
                <div className="column grid-footer">
                    <div className="row">
                        <FontAwesomeIcon style={{fontSize: 14}} icon={faClock}/>
                        <p className="full-size bold">{formatDate(latestDateStr)}</p>
                    </div>
                    <div className="seperator-half" />
                    <div className="column" style={{padding: 8}}>
                        <div className="row">
                            <p style={{
                                fontSize: 10,
                                color: 'rgba(225,59,59,0.5)',
                                margin: '4px 4px',
                                whiteSpace: "nowrap"
                            }} className="full-size bold text-align-center">최고</p>
                            <p style={{
                                fontSize: 10,
                                color: 'rgba(0, 128, 255, 0.5)',
                                margin: '4px 4px',
                                whiteSpace: "nowrap"
                            }} className="full-size bold text-align-center">평균</p>
                            <p style={{
                                fontSize: 10,
                                color: 'rgba(60, 179, 113, 0.8)',
                                margin: '4px 4px',
                                whiteSpace: "nowrap"
                            }} className="full-size bold text-align-center">최저</p>
                        </div>
                        <div className="row">
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}}
                               className="full-size bold text-align-center">{latestSensorData.temp_max.toFixed(1)}˚C</p>
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}}
                               className="full-size bold text-align-center">{latestSensorData.temp_avg.toFixed(1)}˚C</p>
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}}
                               className="full-size bold text-align-center">{latestSensorData.temp_min.toFixed(1)}˚C</p>
                        </div>
                    </div>
                    <div className="column full-size-width align-center" style={{padding: 8, left: -30, top: 10}}>
                        <BarChart width={240} height={80} data={referenceData} layout="horizontal">
                            <YAxis type="number" domain={[0, 75]} tick={{ fill: "#9d9d9d", fontSize: 10 }}/>
                            <XAxis dataKey="name" type="category" tick={{ fill: "#9d9d9d", fontSize: 10 }} />
                            <Bar dataKey="value" fill="#8884d8" barSize={28}>
                                {referenceData.map((entry, index) => (
                                    <Cell key={`hdc-cell-${index}`} fill={getFillColor(entry.name)} />
                                ))}
                                <LabelList dataKey="value" position="insideBottom" content={renderCustomLabel} />
                            </Bar>
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HalfDonutChartWithPins;
