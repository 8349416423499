import Locations from '../modules/locations.json'

const ITEM_SIZE = 24;
const widthPercentage = ['50px', '100px','150px','200px','250px','300px', '350px', '400px','450px','500px'];

function getStyle(widthPercentage, isFirst, index = 2) {
    if(isFirst){
        return { flex: widthPercentage,
            backgroundColor: index % 2 === 1 ? '#646464' : '#a2a2a2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' };
    }else{
        return { flex: widthPercentage,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        };
    }
}

function TableRow({ index, style, data }) {
    const rowData = data[index];

    const rowStyle = {
        ...style,
        top: ITEM_SIZE * index,
    };

    const bodyStyle = {
        backgroundColor: index % 2 === 1 ? '#ffffff' : '#dcdcdc',
    }

    return (
        <>
            <div style={rowStyle}>
                <div className="row full-size-width" style={bodyStyle} >
                    <div className="column align-center" style={{
                        ...getStyle(widthPercentage[1], false, index),
                        borderLeft: "1px solid #00000050",
                        borderBottom: "1px solid #00000050",
                        borderRight: "1px solid #00000050",
                        borderBottomLeftRadius: index === data.length - 1 ? 8 : "0",
                    }}>
                        <p style={{margin: 0, padding: "0.25rem", fontSize: 14, color: "black"}}>{rowData.user_id}</p>
                    </div>
                    <div className="column align-center" style={{
                        ...getStyle(widthPercentage[1], false, index),
                        borderBottom: "1px solid #00000050",
                        borderRight: "1px solid #00000050",
                    }}>
                        <p style={{margin: 0, padding: "0.25rem", fontSize: 14, color: "black"}}>{rowData.user_name}</p>
                    </div>
                    <div className="column align-center" style={{
                        ...getStyle(widthPercentage[4], false, index),
                        borderBottom: "1px solid #00000050",
                        borderRight: "1px solid #00000050",
                        borderBottomRightRadius: index === data.length - 1 ? 8 : "0",
                    }}>
                        <p style={{margin: 0, padding: "0.25rem", fontSize: 14, color: "black"}}>{rowData.user_phone}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export {ITEM_SIZE, TableRow, widthPercentage, getStyle};
