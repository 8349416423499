function formatPhoneNumber(input) {
    const nums = input.replace(/\D/g, "");

    // 휴대폰 번호 형식
    if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
        return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
    }

    return nums;
}

const formatDate = (option, date = new Date()) => {
    if(option === "YYYY-MM-dd"){
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    return null;
}

export { formatPhoneNumber, formatDate }