import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/CCTV.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp, faExclamationTriangle,
    faFolder, faInfoCircle, faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../page/Main";
import { BaseAutocompleteInput } from "../BaseComponents/BaseAutocompleteInput";
import locationsData from "../modules/locations.json";
import { faChartCandlestick, faLocationPen } from "@fortawesome/pro-duotone-svg-icons";
import { backendServer } from "../App";
import { BaseButton, BaseButtonAwesome, BaseButtonAwesomeTrans } from "../BaseComponents/BaseButton";
import BaseInput from "../BaseComponents/BaseInput";
import { BaseSelector } from "../BaseComponents/BaseSelector";
import { faCars, faExclamationCircle, faLocationArrow, faPeople, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from "../BaseComponents/BaseNoData";
import { BaseLoading, BaseLoadingInContainer } from "../BaseComponents/BaseLoading";
import {
    getHeaderFixedColumnStyle,
    getStyle,
    ITEM_SIZE,
    TableRow,
    widthPercentage
} from "../tablerows/sensorData_TableRow";
import { faLeft } from "@fortawesome/sharp-thin-svg-icons";
import {
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faPlateUtensils
} from "@fortawesome/pro-thin-svg-icons";
import { BaseAlert, BaseAlertDanger, BaseAlertWarn } from "../BaseComponents/BaseAlert";
import { faLocationCircle } from "@fortawesome/pro-light-svg-icons";
import MyCalendar from "../modules/MyCalendar";
import * as XLSX from 'xlsx-js-style';
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";

// UTC 시간에서 KST(한국 표준시)로 변환하는 함수
function toKSTDate(date) {
    const KST_OFFSET = 9 * 60 * 60 * 1000; // UTC에서 9시간 차이
    const localDate = new Date(date.getTime() + KST_OFFSET);
    return localDate.toISOString().split('T')[0]; // 날짜만 반환
}

function SensorData() {
    // 알림 관련 상태 값들
    const [alertTimeout, setAlertTimeout] = useState(null);
    const [isAlert, setIsAlert] = useState(-1); // -1이면 알림 비활성화
    const [alertMsg, setAlertMsg] = useState("");

    // 알림을 일정 시간 후에 사라지게 설정하는 함수
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }
        setIsAlert(alertType);

        const timeoutId = setTimeout(() => {
            setIsAlert(-1); // 알림 비활성화
        }, timeOut);
        setAlertTimeout(timeoutId);
    };

    // 기본적으로 현재 날짜를 KST로 변환
    const currentDate = new Date();
    const end_date = toKSTDate(currentDate); // 오늘 날짜
    currentDate.setDate(currentDate.getDate() - 7);
    const start_date = toKSTDate(currentDate); // 일주일 전 날짜

    // 초기 선택 위치 값 설정
    const initLocation = {
        code: -99,
        name: '',
        node: -99,
        dx: -99,
        dy: -99
    };

    const { focusLocation, setFocusLocation } = useContext(GlobalContext); // 전역 상태 값 가져오기
    const [selectLocation, setSelectLocation] = useState(initLocation); // 선택된 위치 값 저장

    // 검색 조건 값 저장
    const [searchValues, setSearchValues] = useState({
        start_date: start_date,
        end_date: end_date,
        camera_code: 1,
        name: '',
        page_size: 100,
    });

    const [searchedValues, setSearchedValues] = useState({
        start_date: start_date,
        end_date: end_date,
        camera_code: 1,
        name: '',
        page_size: 100,
    });

    // 데이터 조회와 관련된 상태 값들
    const [page, setPage] = useState(1);
    const [sensorDatas, setSensorDatas] = useState([]); // 센서 데이터 저장
    const [sortDirection, setSortDirection] = useState('desc'); // 정렬 방향
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태

    // 페이지네이션 관련 상태
    const divRef = useRef(null);
    const containerRef = useRef(null);
    const [listHeight, setListHeight] = useState(428);
    const [containerWidth, setContainerWidth] = useState(0);

    const [startPage, setStartPage] = useState(1);
    const maxPageButtons = 10;

    // containerRef의 크기 변화 감지 (테이블 너비)
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width); // 컨테이너의 너비 업데이트
            }
        });
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current); // 요소 감시 시작
        }
        return () => {
            resizeObserver.disconnect(); // 감시 중지
        };
    }, [containerRef]);

    // divRef의 크기 변화 감지 (테이블 높이)
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height); // 테이블 높이 업데이트
            }
        });
        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    // 지역 데이터 가져오기
    const datas = locationsData.locations.reduce((acc, location) => {
        for (let i = 1; i <= location.nodeCount; i++) {
            acc.push({
                code: location.code,
                name: location.name,
                node: i,
                dx: location.dx,
                dy: location.dy
            });
        }
        return acc;
    }, []);

    // 정렬 순서 토글
    const toggleSortOrder = () => {
        const sortedDatas = [...sensorDatas.datas].sort((a, b) => {
            const timeA = new Date(a.create_at);
            const timeB = new Date(b.create_at);
            return sortDirection === 'asc' ? timeB - timeA : timeA - timeB; // 오름차순/내림차순 토글
        });
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); // 정렬 방향 변경
        setSensorDatas({ ...sensorDatas, datas: sortedDatas });
    };

    // 센서 데이터 검색 함수
    const SearchDatas = async (old = false) => {
        if (selectLocation.code === -99) {
            setAlertMsg("검색할 개소를 선택해주세요.");
            setIsAlertWithTimeout(2);
            return;
        }

        try {
            const searchCondition = old ? searchedValues : searchValues;
            setIsLoading(true);
            const response = await fetch(`https://${backendServer}/get/sensorDatas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'paging',
                    ...searchCondition,
                    locations_code: selectLocation.code,
                    camera_code: selectLocation.node,
                    page: page,
                    sort: sortDirection
                }),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.datas) {
                setSensorDatas(result); // 응답 받은 데이터를 상태에 저장
            } else {
                setSensorDatas([]);
            }
        } catch (error) {
            console.error('데이터 검색 중 오류 발생:', error);
        } finally {
            setIsLoading(false); // 로딩 상태 해제
        }
    };

    // 검색 버튼 클릭 시 호출
    const handleSearch = async () => {
        setPage(1);
        setStartPage(1);
        if (selectLocation.code !== -99) {
            setSearchedValues({ ...searchValues, name: selectLocation.name, node: selectLocation.node });
        }
        await SearchDatas();
    };

    // 페이지나 정렬 순서 변경 시 검색 호출
    useEffect(() => {
        if (selectLocation.code !== -99) {
            SearchDatas(true);
        }
    }, [page, sortDirection]);

    // 엑셀로 데이터 내보내기
    const handleExportExcel = () => {
        if (sensorDatas.datas.length === 0) {
            setAlertMsg("출력할 데이터가 없습니다.");
            setIsAlertWithTimeout(1);
            return;
        }

        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }
            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        // 엑셀 양식 설정
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        // 엑셀 양식: 열 너비 설정
        const columnWidths = [
            { wch: 1.86 },
            { wch: 3.00 },
            { wch: 19.43 },
            { wch: 5.29 },
            { wch: 14.14 },
            { wch: 28.14 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 2.71 },
            { wch: 9.51 },
            { wch: 10.71 },
            { wch: 3.29 },
            { wch: 4.00 },
            { wch: 2.71 },
            { wch: 4.43 },
            { wch: 5.41 },
            { wch: 9.57 },
            { wch: 8.00 },
        ];

        ws['!cols'] = columnWidths;

        const data = [
            [searchedValues.name, '', '', '', '', '', '', '', ''],
            [searchedValues.camera_code + "번 개소", '', '', '', '', '', '', '', ''],
            [searchedValues.start_date, '', '', '', '', '', '', '', ''],
            [searchedValues.end_date, '', '', '', '', '', '', '', '', ''],
        ];

        const header = [
            ['No', '위치', '개소', '', '시간', '상태', '', '', '' , '' , '평균 온도', '', '', '최고 온도', '', '', '최저 온도', ''],
        ];

        const body = [];
        for (let i = 0; i < sensorDatas.datas.length; i++) {
            const history = sensorDatas.datas[i];
            const row = [
                i + 1,
                searchedValues.name,
                parseInt(history.camera_code) + "번 카메라",
                '',
                formatDate(history.create_at),
                history.status === 0 ? '정상' : "비정상",
                '',
                '',
                '',
                '',
                history.temp_avg.toFixed(1) + "˚C",
                '',
                '',
                history.temp_max.toFixed(1) + "˚C",
                '',
                '',
                history.temp_min.toFixed(1) + "˚C",
                '',
            ];
            body.push(row);
        }

        // 엑셀 파일로 저장
        XLSX.utils.sheet_add_aoa(ws, data, { origin: 'D4' });
        XLSX.utils.sheet_add_aoa(ws, header, { origin: 'B10' });
        XLSX.utils.sheet_add_aoa(ws, body, { origin: 'B11' });

        const baseFileName = `[${searchedValues.name}-${searchedValues.camera_code}번 개소]_${searchedValues.page_size}_데이터 차트(${page}페이지) (${searchedValues.start_date}~${searchedValues.end_date})`;
        XLSX.writeFile(wb, `${baseFileName}.xlsx`);
    };

    return (
        <>
            <div className="row full-size" style={{ position: "relative", overflow: "hidden" }}>
                <div className="column full-size" style={{ width: "80%", position: "relative", overflow: "hidden" }}>
                    <div className="row full-size-width" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <FontAwesomeIcon icon={faChartCandlestick} size="xs" style={{ margin: 8 }} />
                        <p className="bold"
                           style={{
                               width: "calc(80% + 32px)",
                               margin: "16px 16px 16px 0",
                               fontSize: 13,
                               color: "white"
                           }}>온도
                            데이터 보기</p>
                    </div>
                    {/* 검색 영역 */}
                    <div className="seperator-half-top" />
                    <div className="row full-size-width align-center">
                        <div className="column full-size-width">
                            <div className="row full-size-width category-texts align-center">
                                <p>페이지 당 갯수</p>
                                <p>검색 범위(시작)</p>
                                <p>검색 범위(끝)</p>
                                <p>검색할 개소</p>
                            </div>
                            <div className="row full-size-width">
                                <BaseSelector
                                    options={[
                                        { value: '100', label: '100' },
                                        { value: '200', label: '200' },
                                        { value: '300', label: '300' },
                                        { value: '600', label: '600' },
                                        { value: '1200', label: '1200' },
                                        { value: '2400', label: '2400' },
                                        { value: '4800', label: '4800' },
                                    ]}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            page_size: e.target.value
                                        }));
                                    }}
                                />
                                <BaseInput
                                    name="start_date"
                                    value={searchValues.start_date}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            start_date: e.target.value
                                        }));
                                    }}
                                    type="date"
                                />
                                <BaseInput
                                    name="end_date"
                                    value={searchValues.end_date}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            end_date: e.target.value
                                        }));
                                    }}
                                    type="date"
                                />
                                <BaseAutocompleteInput
                                    data={datas}
                                    displayFunc={data => `${data.name}(${data.node}번 카메라)`}
                                    suggestionFunc={data => `${data.name}\t${data.node}번 카메라`}
                                    suggestionClick={(data) => {
                                        setSelectLocation(data || initLocation);
                                    }}
                                    placeholder="지역명으로 검색해보세요."
                                />
                                <div style={{ borderBottom: "1px solid #cccccc50", marginLeft: "1rem", marginRight: "1rem", boxSizing: "border-box" }} />
                            </div>
                        </div>
                        <BaseButtonAwesome icon={faSearch} onClick={handleSearch} />
                    </div>

                    {/* 검색된 데이터 테이블 */}
                    <div className="seperator-half-top" />
                    <div className="column main-table-body" ref={containerRef}>
                        {isLoading ? (
                            <div className="column full-size align-center">
                                <BaseLoadingInContainer />
                            </div>
                        ) : (
                            <div className="table-container-h1" ref={divRef}>
                                {sensorDatas.length === 0 ? (
                                    <BaseNoData />
                                ) : (
                                    <div className="column table-body-container">
                                        <div className="row full-size-width table-header" style={{ height: 24 }}>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[0],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">순번</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[2],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">충전소</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[2],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">카메라</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     cursor: "pointer",
                                                     width: widthPercentage[4],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}
                                                 onClick={toggleSortOrder}>
                                                <p className="text-align-center">시간</p>
                                                <FontAwesomeIcon
                                                    icon={sortDirection === 'asc' ? faChevronUp : faChevronDown}
                                                    size={"xs"} style={{
                                                    color: "black",
                                                    position: "absolute",
                                                    right: 8,
                                                    top: "50%",
                                                    transform: "translateY(-50%)"
                                                }} />
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[2],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">상태</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[2],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">평균온도</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[1],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">최고온도</p>
                                            </div>
                                            <div className="column align-center"
                                                 style={{
                                                     width: widthPercentage[1],
                                                     backgroundColor: '#ffffff',
                                                     overflow: 'hidden',
                                                     textOverflow: 'ellipsis',
                                                     whiteSpace: 'nowrap'
                                                 }}>
                                                <p className="text-align-center">최저온도</p>
                                            </div>
                                        </div>
                                        <List
                                            width={containerWidth}
                                            height={listHeight - 25}
                                            className="table full-size-width"
                                            itemCount={sensorDatas.datas.length}
                                            itemSize={ITEM_SIZE}
                                            itemData={{
                                                datas: sensorDatas.datas,
                                                containerWidth,
                                                page,
                                                page_size: searchedValues.page_size
                                            }}>
                                            {TableRow}
                                        </List>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="seperator-half-top" />
                    {/* 페이지네이션 */}
                    {sensorDatas.length !== 0 && (
                        <div className="row full-size-width align-center page-buttons">
                            {startPage > 1 && (
                                <>
                                    <button className="column align-center"
                                            onClick={() => {
                                                setStartPage(1);
                                                setPage(1);
                                            }}
                                            style={{
                                                margin: "0 0.25rem",
                                                width: 30,
                                                height: 30,
                                                boxSizing: "border-box",
                                                border: "1px solid black",
                                                borderRadius: "0.5rem",
                                            }}>
                                        <FontAwesomeIcon icon={faChevronsLeft} />
                                    </button>
                                    <button className="column align-center"
                                            onClick={() => {
                                                setStartPage(startPage - maxPageButtons);
                                            }}
                                            style={{
                                                margin: "0 0.25rem",
                                                width: 30,
                                                height: 30,
                                                boxSizing: "border-box",
                                                border: "1px solid black",
                                                borderRadius: "0.5rem",
                                            }}>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                </>
                            )}
                            {Array.from({ length: Math.min(maxPageButtons, sensorDatas.page_count - startPage + 1) }, (_, i) => startPage + i).map(pageNumber => (
                                <button
                                    key={pageNumber}
                                    style={{
                                        margin: "0 0.25rem",
                                        width: 30, height: 30,
                                        boxSizing: "border-box",
                                        backgroundColor: pageNumber === page ? "#3e7ee5" : "#cccccc",
                                        color: pageNumber === page ? "white" : "black",
                                        border: "1px solid #00000080",
                                        borderRadius: "0.5rem",
                                    }}
                                    onClick={() => setPage(pageNumber)}>
                                    {pageNumber}
                                </button>
                            ))}
                            {startPage + maxPageButtons - 1 < sensorDatas.page_count && (
                                <>
                                    <button className="column align-center"
                                            onClick={() => {
                                                setStartPage(startPage + maxPageButtons);
                                            }}
                                            style={{
                                                margin: "0 0.25rem",
                                                width: 30,
                                                height: 30,
                                                boxSizing: "border-box",
                                                border: "1px solid black",
                                                borderRadius: "0.5rem",
                                            }}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                    <button className="column align-center"
                                            onClick={() => {
                                                const remainder = sensorDatas.page_count % maxPageButtons;
                                                const startPageForLastSet = remainder === 0 ? sensorDatas.page_count - maxPageButtons + 1 : sensorDatas.page_count - remainder + 1;
                                                setPage(sensorDatas.page_count);
                                                setStartPage(startPageForLastSet);
                                            }}
                                            style={{
                                                margin: "0 0.25rem",
                                                width: 30,
                                                height: 30,
                                                boxSizing: "border-box",
                                                border: "1px solid black",
                                                borderRadius: "0.5rem",
                                            }}>
                                        <FontAwesomeIcon icon={faChevronsRight} />
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>

                {/* 엑셀 다운로드 버튼 */}
                <div className="column full-size" style={{ width: "20%", position: "relative", overflow: 'hidden' }}>
                    <div className="row" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <p className="bold" style={{ width: "20%", marginLeft: 16, fontSize: 13, color: "white" }}>개요</p>
                    </div>
                    <MyCalendar events={[
                        {
                            start: new Date(searchedValues.start_date),
                            end: new Date(searchedValues.end_date),
                            title: "주의",
                            resource: {
                                count: sensorDatas.total_temp_warning,
                            },
                        },
                        {
                            start: new Date(searchedValues.start_date),
                            end: new Date(searchedValues.end_date),
                            title: "경계",
                            resource: {
                                count: sensorDatas.total_temp_alert,
                            },
                        },
                        {
                            start: new Date(searchedValues.start_date),
                            end: new Date(searchedValues.end_date),
                            title: "위험",
                            resource: {
                                count: sensorDatas.total_temp_danger,
                            },
                        },
                    ]} />
                    <div className="column full-size-width" style={{ position: "relative" }}>
                        <p className="less-important-info" style={{ padding: "0 0.5rem", margin: 0 }}>※검색된 범위(현재페이지)만 추출하여 저장합니다.</p>
                        <button
                            className="download-button"
                            onClick={handleExportExcel}
                        >
                            엑셀 다운로드
                        </button>

                    </div>
                </div>
            </div>

            {/* 알림창 */}
            {isAlert === 0 && <BaseAlert msg={alertMsg} />}
            {isAlert === 1 && <BaseAlertWarn msg={alertMsg} />}
            {isAlert === 2 && <BaseAlertDanger msg={alertMsg} />}
        </>
    );
}

export default SensorData;
