import React, {useState, useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko';
import './lib/css/calendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-duotone-svg-icons";
import {
    faBookmark,
    faClockEight,
    faTimes,
    faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import {formatDate} from "./lib/tool";
import {backendServer} from "../App";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";

moment.locale('ko');
const localizer = momentLocalizer(moment);

const getTextColorBasedOnBgColor = (bgColor) => {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);

    const brightness = (0.299 * r) + (0.587 * g) + (0.114 * b);

    return brightness > 128 ? 'black' : 'white';
};

const Event = ({ event }) => {
    const textColor = getTextColorBasedOnBgColor(event.color);

    return (
        <div
            style={{
            background: event.color,
            margin: '2px 0',
            padding: '2px 5px',
            borderRadius: '5px',
            color: textColor
        }}>
            <div>{event.title}</div>
        </div>
    );
};

const ScheduleCalendar = ({events, role, changeDate, getSchedule}) => {
    const [date, setDate] = useState(
        events && events.length > 0 ? new Date(events[0].end) : new Date()
    );

    const [ selectedSchedule, setSelectedSchedule ] = useState(null)
    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [ alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        setAlertTimeout(timeoutId);
    }

    const [scheduleValue, setScheduleValue] = useState({
        schedule_start_date: formatDate("YYYY-MM-dd"),
        schedule_end_date: formatDate("YYYY-MM-dd"),
        schedule_title: '',
        schedule_important: false,
        schedule_alert: false,
        schedule_color: '#000000',
        schedule_color_backup: '#000000',
    })

    const [ showRegForm, setShowRegForm ] = useState(false);
    const [ alertMsgInBlock, setAlertMsgInBlock ] = useState("");

    // 연도와 월을 상태로 관리
    const [year, setYear] = useState(date.getFullYear());
    const [month, setMonth] = useState(date.getMonth() + 1);

    useEffect(() => {
        setYear(date.getFullYear());
        setMonth(date.getMonth() + 1);
        changeDate(date.getFullYear(), date.getMonth() + 1)
    }, [date]);

    const handlePrev = () => {
        const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        setDate(prevMonth);
    };

    const handleNext = () => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        setDate(nextMonth);
    };

    const handleToday = () => {
        const today = new Date();
        setDate(today);
    };

    const handleShowRegForm = () => {
        setShowRegForm(!showRegForm);
    }

    const handleEventSelect = (event) => {
        setSelectedSchedule(prevValue => ({ ...event, start: formatDate("YYYY-MM-dd", new Date(event.start)), end: formatDate("YYYY-MM-dd", new Date(event.end)), alert: false, color_backup: "#0000000"}));
    };

    const monthNames = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

    const handleChange = (event) => {
        const selectedDate = new Date(event.target.value);
        setDate(selectedDate);
    };

    const handleScheduleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if(type === "checkbox"){
            setScheduleValue(prevValues => ({
                ...prevValues,
                [name]: checked
            }));
        }else{
            setScheduleValue(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }

    const handleSelectedScheduleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if(type === "checkbox"){
            setSelectedSchedule(prevValues => ({
                ...prevValues,
                [name]: checked
            }));
        }else{
            setSelectedSchedule(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }

    const handleRegSchedule = async () => {
        const start = new Date(scheduleValue.schedule_start_date);
        const end = new Date(scheduleValue.schedule_end_date);
        if(start > end){
            setAlertMsgInBlock("시작일이 종료일보다 클 수 없습니다.")
        }

        if(scheduleValue.schedule_title === ""){
            setAlertMsgInBlock("제목은 비어있을 수 없습니다.")
        }

        try {
            const response = await fetch(`https://${backendServer}/schedule/reg`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(scheduleValue),
            });

            if (response.ok) {
                setAlertMsg("성공적으로 일정을 추가했습니다.")
                setIsAlertWithTimeout(0);
                setScheduleValue({
                    schedule_start_date: formatDate("YYYY-MM-dd"),
                    schedule_end_date: formatDate("YYYY-MM-dd"),
                    schedule_title: '',
                    schedule_important: false,
                    schedule_alert: false,
                    schedule_color: '#000000',
                    schedule_color_backup: '#000000',
                });
                getSchedule();
            } else {
                const result = await response.json();
                throw new Error(result.message);
            }
        }catch(e){
            setAlertMsg("일정 추가 중 에러가 발생했습니다.")
            setIsAlertWithTimeout(2);
        }finally {
            setAlertMsgInBlock("");
        }
    }

    const handleModifySchedule = async () => {
        const start = new Date(selectedSchedule.start);
        const end = new Date(selectedSchedule.end);

        if(start > end){
            setAlertMsgInBlock("시작일이 종료일보다 클 수 없습니다.")
        }

        if(selectedSchedule.title === ""){
            setAlertMsgInBlock("제목은 비어있을 수 없습니다.")
        }

        try {
            const response = await fetch(`https://${backendServer}/schedule/modify`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(selectedSchedule),
            });

            if (response.ok) {
                setAlertMsg("성공적으로 일정을 수정했습니다.")
                setIsAlertWithTimeout(0);
                setSelectedSchedule(null);
                getSchedule();
            } else {
                const result = await response.json();
                throw new Error(result.message);
            }
        }catch(e){
            setAlertMsg("일정 추가 중 에러가 발생했습니다.")
            setIsAlertWithTimeout(2);
        }finally {
            setAlertMsgInBlock("");
        }
    }

    const handleDeleteSchedule = async () => {
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm(`정말 해당 스케쥴을 삭제하시겠습니까?`);
        if (!isConfirmed) {
            return;
        }

        try {
            const response = await fetch(`https://${backendServer}/schedule/delete`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(selectedSchedule),
            });

            if (response.ok) {
                setAlertMsg("성공적으로 일정을 삭제했습니다.")
                setIsAlertWithTimeout(0);
                setSelectedSchedule(null);
                getSchedule();
            } else {
                const result = await response.json();
                throw new Error(result.message);
            }
        }catch(e){
            setAlertMsg("일정 추가 중 에러가 발생했습니다.")
            setIsAlertWithTimeout(2);
        }finally {
            setAlertMsgInBlock("");
        }
    }

    return (
        <>
            <div className="column full-size align-center"
                 style={{background: "white", borderRadius: "0.5rem", padding: "1rem 0.5rem", boxSizing: "border-box"}}>
                {selectedSchedule && (
                    <div className="row full-size align-center" style={{background: "#00000080", position: "absolute", zIndex: 100}}>
                        <div className="column full-size align-center" style={{position: "relative"}}>
                            <div style={{
                                opacity: 1,
                                top: 32,
                                position: "absolute",
                                background: "white",
                                width: 300,
                                height: "auto",
                                zIndex: 101,
                                borderRadius: "0.5rem",
                                border: "1px solid #00000050",
                                padding: "0.5rem",
                                transition: "opacity 0.5s ease-in-out"
                            }}>
                                <div className="column align-center">
                                    <div className="row full-size-width">
                                        <button onClick={handleDeleteSchedule} style={{
                                            border: "1px solid #cbcbcb",
                                            background: "transparent",
                                            borderRadius: 5,
                                            cursor: "pointer"
                                        }}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                        <p className="full-size-width"
                                           style={{
                                               whiteSpace: "nowrap",
                                               color: "black",
                                               fontSize: 14,
                                               margin: "0.5rem 0",
                                               padding: 0,
                                               textAlign: "center",
                                               fontWeight: "bold"
                                           }}>일정 변경</p>
                                        <button onClick={() => setSelectedSchedule(null)} style={{
                                            border: "1px solid #cbcbcb",
                                            background: "transparent",
                                            borderRadius: 5,
                                            cursor: "pointer"
                                        }}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </button>
                                    </div>

                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "red",
                                           fontSize: 10,
                                           margin: "0.5rem 0",
                                           padding: 0,
                                           textAlign: "center"
                                       }}>{alertMsgInBlock}</p>
                                    <div className="column full-size-width">
                                        <p className="full-size-width"
                                           style={{
                                               whiteSpace: "nowrap",
                                               color: "#333333",
                                               fontSize: 10,
                                               margin: 0,
                                               padding: 0,
                                           }}>시작일</p>
                                        <div
                                            className="row input-container align-center full-size-width"
                                            style={{
                                                background: "white",
                                                borderRadius: "4px",
                                                border: "1px solid #000000b0",
                                                margin: "0.5rem 0"
                                            }}>
                                            <FontAwesomeIcon
                                                style={{color: "black", marginLeft: "1rem"}}
                                                fontSize={12} icon={faClockEight}/>
                                            <input
                                                style={{
                                                    padding: "0.5rem",
                                                    fontSize: 12,
                                                    color: "black",
                                                    textAlign: "center"
                                                }}
                                                onChange={handleSelectedScheduleChange}
                                                type="date"
                                                autoComplete={"off"}
                                                name="start"
                                                value={selectedSchedule.start}
                                                className="full-size-width"
                                            />
                                        </div>
                                    </div>
                                    <div className="column full-size-width">
                                        <p className="full-size-width"
                                           style={{
                                               whiteSpace: "nowrap",
                                               color: "#333333",
                                               fontSize: 10,
                                               margin: 0,
                                               padding: 0,
                                           }}>종료일</p>
                                        <div
                                            className="row input-container align-center full-size-width"
                                            style={{
                                                background: "white",
                                                borderRadius: "4px",
                                                border: "1px solid #000000b0",
                                                margin: "0.5rem 0"
                                            }}>
                                            <FontAwesomeIcon
                                                style={{color: "black", marginLeft: "1rem"}}
                                                fontSize={12} icon={faClockEight}/>
                                            <input
                                                style={{
                                                    padding: "0.5rem",
                                                    fontSize: 12,
                                                    color: "black",
                                                    textAlign: "center"
                                                }}
                                                onChange={handleSelectedScheduleChange}
                                                type="date"
                                                autoComplete={"off"}
                                                name="end"
                                                value={selectedSchedule.end}
                                                className="full-size-width"
                                            />
                                        </div>
                                        <div className="column full-size-width">
                                            <p className="full-size-width"
                                               style={{
                                                   whiteSpace: "nowrap",
                                                   color: "#333333",
                                                   fontSize: 10,
                                                   margin: 0,
                                                   padding: 0,
                                               }}>제목</p>
                                            <div
                                                className="row input-container align-center full-size-width"
                                                style={{
                                                    background: "white",
                                                    borderRadius: "4px",
                                                    border: "1px solid #000000b0",
                                                    margin: "0.5rem 0"
                                                }}>
                                                <FontAwesomeIcon
                                                    style={{color: "black", marginLeft: "1rem"}}
                                                    fontSize={12} icon={faBookmark}/>
                                                <input
                                                    style={{
                                                        padding: "0.5rem",
                                                        fontSize: 12,
                                                        color: "black",
                                                        textAlign: "left"
                                                    }}
                                                    onChange={handleSelectedScheduleChange}
                                                    autoComplete={"off"}
                                                    name="title"
                                                    value={selectedSchedule.title}
                                                    className="full-size-width"
                                                />
                                            </div>
                                        </div>
                                        <div className="column full-size-width">
                                            <p className="full-size-width"
                                               style={{
                                                   whiteSpace: "nowrap",
                                                   color: "#333333",
                                                   fontSize: 10,
                                                   margin: 0,
                                                   padding: 0,
                                               }}>기타 설정</p>
                                            <div
                                                className="row input-container align-center full-size-width"
                                                style={{
                                                    background: "white",
                                                    borderRadius: "4px",
                                                    border: "1px solid #000000b0",
                                                    margin: "0.5rem 0"
                                                }}>
                                                <input type="checkbox" name="important"
                                                       id="important"
                                                       style={{display: "none"}}
                                                       checked={selectedSchedule.important}
                                                       onChange={(e) => {
                                                           handleSelectedScheduleChange(e)
                                                           if (e.target.checked) {
                                                               setSelectedSchedule(prevValues => ({
                                                                   ...prevValues,
                                                                   color_backup: prevValues['color'],
                                                                   color: "#E03726"
                                                               }));
                                                           } else {
                                                               setSelectedSchedule(prevValues => ({
                                                                   ...prevValues,
                                                                   color_backup: prevValues['color'],
                                                                   color: prevValues['color_backup']
                                                               }));
                                                           }
                                                       }}
                                                       className="custom-checkbox"
                                                />
                                                <label htmlFor="important"
                                                       className="checkbox-label"
                                                       style={{border: "1px solid #00000080", marginLeft: "0.5rem"}}
                                                >
                                                </label>
                                                <p className="full-size-width"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       color: "#333333",
                                                       fontSize: 12
                                                   }}>중요</p>
                                                <input type="checkbox" name="alert" id="alert"
                                                       style={{display: "none"}}
                                                       checked={selectedSchedule.alert}
                                                       onChange={handleSelectedScheduleChange}
                                                       className="custom-checkbox"
                                                />
                                                <label htmlFor="alert"
                                                       className="checkbox-label"
                                                       style={{border: "1px solid #00000080"}}
                                                >
                                                </label>
                                                <p className="full-size-width"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       color: "#333333",
                                                       fontSize: 12
                                                   }}>알림보내기</p>
                                            </div>
                                            <div className="column full-size-width">
                                                <p className="full-size-width"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       color: "#333333",
                                                       fontSize: 10,
                                                       margin: 0,
                                                       padding: 0,
                                                   }}>색상</p>
                                                <input
                                                    style={{borderRadius: "0.2rem"}}
                                                    type="color"
                                                    name="color"
                                                    value={selectedSchedule.color}
                                                    onChange={handleSelectedScheduleChange}
                                                    className="full-size-width"
                                                    disabled={selectedSchedule.important}
                                                />
                                            </div>
                                            <div className="column full-size-width align-center">
                                                <button
                                                    onClick={handleModifySchedule}
                                                    className="full-size-width bold reg-schedule-button">
                                                    일정 수정
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row full-size-width align-center">
                    <div className="column full-size-width align-left-center">
                        <button onClick={handleToday} style={{
                            border: "1px solid #cbcbcb",
                            background: "transparent",
                            borderRadius: 5,
                            padding: "0.5rem",
                            cursor: "pointer",
                            whiteSpace: "nowrap"
                        }}>
                            오늘
                        </button>
                    </div>
                    <div className="row full-size-width align-center">
                        <button onClick={handlePrev} style={{
                            border: "1px solid #cbcbcb",
                            background: "transparent",
                            borderRadius: 5,
                            cursor: "pointer"
                        }}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                        <div className="column align-center" style={{position: "relative"}}>
                            <p style={{margin: "0 0.5rem", padding: 0, color: "#333333", fontSize: 10}}>{year}</p>
                            <p style={{
                                margin: "0 0.5rem",
                                padding: 0,
                                color: "black",
                                fontSize: 16
                            }}>{monthNames[month - 1]}</p>
                            {/* 숨겨진 날짜 입력 필드 */}
                            <input
                                type="date"
                                style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    cursor: "pointer",
                                    zIndex: 4,
                                    width: '100%',
                                    height: '100%'
                                }}
                                onChange={handleChange}
                            />
                        </div>
                        <button onClick={handleNext}
                                style={{
                                    border: "1px solid #cbcbcb",
                                    background: "transparent",
                                    borderRadius: 5, cursor: "pointer"
                                }}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                    <div className="column full-size-width align-right-center" style={{position: "relative"}}>
                        {role === 1 && (
                            <>
                                <button onClick={handleShowRegForm} style={{
                                    border: "1px solid #cbcbcb",
                                    background: "transparent",
                                    borderRadius: 5,
                                    padding: "0.5rem",
                                    cursor: "pointer",
                                    whiteSpace: "nowrap"
                                }}>
                                    일정추가
                                </button>
                                <div style={{
                                    opacity: showRegForm ? 1 : 0,
                                    top: 32,
                                    width: 300,
                                    height: "auto",
                                    position: "absolute",
                                    background: "white",
                                    zIndex: showRegForm ? 99 : -1,
                                    borderRadius: "0.5rem",
                                    border: "1px solid #00000050",
                                    padding: "0.5rem",
                                    transition: "opacity 0.5s ease-in-out"
                                }}>
                                    <div className="column align-center">
                                        <p className="full-size-width"
                                           style={{
                                               whiteSpace: "nowrap",
                                               color: "red",
                                               fontSize: 10,
                                               margin: "0.5rem 0",
                                               padding: 0,
                                               textAlign: "center"
                                           }}>{alertMsgInBlock}</p>
                                        <div className="column full-size-width">
                                            <p className="full-size-width"
                                               style={{
                                                   whiteSpace: "nowrap",
                                                   color: "#333333",
                                                   fontSize: 10,
                                                   margin: 0,
                                                   padding: 0,
                                               }}>시작일</p>
                                            <div
                                                className="row input-container align-center full-size-width"
                                                style={{
                                                    background: "white",
                                                    borderRadius: "4px",
                                                    border: "1px solid #000000b0",
                                                    margin: "0.5rem 0"
                                                }}>
                                                <FontAwesomeIcon
                                                    style={{color: "black", marginLeft: "1rem"}}
                                                    fontSize={12} icon={faClockEight}/>
                                                <input
                                                    style={{
                                                        padding: "0.5rem",
                                                        fontSize: 12,
                                                        color: "black",
                                                        textAlign: "center"
                                                    }}
                                                    onChange={handleScheduleChange}
                                                    type="date"
                                                    autoComplete={"off"}
                                                    name="schedule_start_date"
                                                    value={scheduleValue.schedule_start_date}
                                                    className="full-size-width"
                                                />
                                            </div>
                                        </div>
                                        <div className="column full-size-width">
                                            <p className="full-size-width"
                                               style={{
                                                   whiteSpace: "nowrap",
                                                   color: "#333333",
                                                   fontSize: 10,
                                                   margin: 0,
                                                   padding: 0,
                                               }}>종료일</p>
                                            <div
                                                className="row input-container align-center full-size-width"
                                                style={{
                                                    background: "white",
                                                    borderRadius: "4px",
                                                    border: "1px solid #000000b0",
                                                    margin: "0.5rem 0"
                                                }}>
                                                <FontAwesomeIcon
                                                    style={{color: "black", marginLeft: "1rem"}}
                                                    fontSize={12} icon={faClockEight}/>
                                                <input
                                                    style={{
                                                        padding: "0.5rem",
                                                        fontSize: 12,
                                                        color: "black",
                                                        textAlign: "center"
                                                    }}
                                                    onChange={handleScheduleChange}
                                                    type="date"
                                                    autoComplete={"off"}
                                                    name="schedule_end_date"
                                                    value={scheduleValue.schedule_end_date}
                                                    className="full-size-width"
                                                />
                                            </div>
                                            <div className="column full-size-width">
                                                <p className="full-size-width"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       color: "#333333",
                                                       fontSize: 10,
                                                       margin: 0,
                                                       padding: 0,
                                                   }}>제목</p>
                                                <div
                                                    className="row input-container align-center full-size-width"
                                                    style={{
                                                        background: "white",
                                                        borderRadius: "4px",
                                                        border: "1px solid #000000b0",
                                                        margin: "0.5rem 0"
                                                    }}>
                                                    <FontAwesomeIcon
                                                        style={{color: "black", marginLeft: "1rem"}}
                                                        fontSize={12} icon={faBookmark}/>
                                                    <input
                                                        style={{
                                                            padding: "0.5rem",
                                                            fontSize: 12,
                                                            color: "black",
                                                            textAlign: "left"
                                                        }}
                                                        onChange={handleScheduleChange}
                                                        autoComplete={"off"}
                                                        name="schedule_title"
                                                        value={scheduleValue.schedule_title}
                                                        className="full-size-width"
                                                    />
                                                </div>
                                            </div>
                                            <div className="column full-size-width">
                                                <p className="full-size-width"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       color: "#333333",
                                                       fontSize: 10,
                                                       margin: 0,
                                                       padding: 0,
                                                   }}>기타 설정</p>
                                                <div
                                                    className="row input-container align-center full-size-width"
                                                    style={{
                                                        background: "white",
                                                        borderRadius: "4px",
                                                        border: "1px solid #000000b0",
                                                        margin: "0.5rem 0"
                                                    }}>
                                                    <input type="checkbox" name="schedule_important"
                                                           id="schedule_important"
                                                           style={{display: "none"}}
                                                           checked={scheduleValue.schedule_important}
                                                           onChange={(e) => {
                                                               handleScheduleChange(e)
                                                               if (e.target.checked) {
                                                                   setScheduleValue(prevValues => ({
                                                                       ...prevValues,
                                                                       schedule_color_backup: prevValues['schedule_color'],
                                                                       schedule_color: "#E03726"
                                                                   }));
                                                               } else {
                                                                   setScheduleValue(prevValues => ({
                                                                       ...prevValues,
                                                                       schedule_color_backup: prevValues['schedule_color'],
                                                                       schedule_color: prevValues['schedule_color_backup']
                                                                   }));
                                                               }
                                                           }}
                                                           className="custom-checkbox"
                                                    />
                                                    <label htmlFor="schedule_important"
                                                           className="checkbox-label"
                                                           style={{border: "1px solid #00000080", marginLeft: "0.5rem"}}
                                                    >
                                                    </label>
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#333333",
                                                           fontSize: 12
                                                       }}>중요</p>
                                                    <input type="checkbox" name="schedule_alert" id="schedule_alert"
                                                           style={{display: "none"}}
                                                           checked={scheduleValue.schedule_alert}
                                                           onChange={handleScheduleChange}
                                                           className="custom-checkbox"
                                                    />
                                                    <label htmlFor="schedule_alert"
                                                           className="checkbox-label"
                                                           style={{border: "1px solid #00000080"}}
                                                    >
                                                    </label>
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#333333",
                                                           fontSize: 12
                                                       }}>알림보내기</p>
                                                </div>
                                                <div className="column full-size-width">
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#333333",
                                                           fontSize: 10,
                                                           margin: 0,
                                                           padding: 0,
                                                       }}>색상</p>
                                                    <input
                                                        style={{borderRadius: "0.2rem"}}
                                                        type="color"
                                                        name="schedule_color"
                                                        value={scheduleValue.schedule_color}
                                                        onChange={handleScheduleChange}
                                                        className="full-size-width"
                                                        disabled={scheduleValue.schedule_important}
                                                    />
                                                </div>
                                                <div className="column full-size-width align-center">
                                                    <button
                                                        onClick={handleRegSchedule}
                                                        className="full-size-width bold reg-schedule-button">
                                                        일정 추가
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="seperator-half-top"/>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    date={date}
                    onNavigate={setDate}
                    style={{height: 720, width: "100%"}}
                    defaultView="month"
                    views={["month"]}
                    messages={{
                        today: "오늘",
                        next: "다음",
                        previous: "이전",
                        month: "월",
                        week: "주",
                        day: "일"
                    }}
                    onSelectEvent={handleEventSelect}
                    components={{
                        event: Event,
                        toolbar: () => null,
                    }}
                />
            </div>
            {isAlert === 0 && (
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </>
    );
};

export default ScheduleCalendar;