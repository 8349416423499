import React, {useState} from 'react';
import {PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer, LabelList, Sector} from 'recharts';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCircle} from "@fortawesome/free-solid-svg-icons";

const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={4} textAnchor="middle" fill={fill} fontSize={12}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text fontSize={9} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={3} textAnchor={textAnchor} fill="#ffffff">
                {`${value}%`}
            </text>
        </g>
    );
};

const SimpleDoughnutChart = ({ statusCounts }) => {
    const [activeIndex ,setActiveIndex ] = useState(0);
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const totalDangerCount = statusCounts.reduce((acc, item) => acc + item.value, 0);

    const chartData = statusCounts.map(item => ({
        name: item.name, // "X번 개소"
        value: parseFloat(((item.value / totalDangerCount) * 100).toFixed(2)),
    }));

    return (
        <div style={{width: 350, height: 200, boxSizing: "border-box" }}>
            <PieChart
                width={350}
                height={200}
            >
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={chartData}
                    innerRadius={35}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                >
                    {
                        chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
            </PieChart>
        </div>
    );
};

export default SimpleDoughnutChart;
