import React, {useState} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Area,
    ReferenceArea
} from 'recharts';
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarChart, faChainSlash, faCheckCircle,
    faCircle, faComment,
    faCommentSlash,
    faLinkSlash, faTextSlash, faTint,
    faTintSlash,
    faWater
} from "@fortawesome/free-solid-svg-icons";
import SimpleDoughnutChart from "./SimpleDoughnutChart";
import RTSPPlayer from "./RTSPPlayer";
import temp2 from "../temp/images/2023072701002809800155501.jpg";
import temp3 from "../temp/images/417397_429506_4251.jpg";
import temp4 from "../temp/images/346389_239633_5246.jpg";
import MobileCardSlider from "./MobileCardSlider";
import {faTemperatureHalf} from "@fortawesome/pro-regular-svg-icons";

const colors = {
    sensor1: [
        '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57',
        '#ffc658', '#ff8e53', '#ff9999', '#b28dff'
    ]
};

const transformData = (data, setupValue) => {
    const transformed = [];

    Object.keys(data).forEach((cameraCode, index) => {
        data[cameraCode].forEach((item) => {
            const sensorValue = item.temp_avg.toFixed(1);
            const ratio = (parseFloat(sensorValue) / setupValue) * 100;
            console.log("sensorValue : " , sensorValue)
            console.log("Ratio : " , ratio)
            console.log("setupValue : " , setupValue)
            let status = '안전';
            if (ratio >= 80) {
                status = '위험';
            } else if (ratio >= 50 && ratio < 80) {
                status = '주의';
            } else if (ratio >= 30 && ratio < 50) {
                status = '경계';
            }
            transformed.push({
                cameraCode,
                create_at: moment(item.create_at, "YYYY-MM-DD HH:mm:ss").valueOf(),
                temp_avg: item.temp_avg.toFixed(1),
                temp_min: item.temp_min.toFixed(1),
                temp_max: item.temp_max.toFixed(1),
                sensorValue,
                status: status
            });
        });
    });

    return transformed.sort((a, b) => a.create_at - b.create_at); // 시간순으로 정렬
};

const CustomTooltip = ({ active, payload, label, rate = "˚C" }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ fontSize: "12px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '10px' }}>
                <p className="label">{`${moment(payload[0].payload.create_at).format('MM월 DD일 HH:mm:ss')}`}</p>
                <div className="column" style={{ fontSize: "10px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '5px' }}>
                    {payload.map((data, index) => {
                        if(data.name === data.payload.cameraCode) {
                            return (
                                <div className="row align-center">
                                    <FontAwesomeIcon icon={faCircle}
                                                     style={{fontSize: "6px", color: colors.sensor1[index]}}/>
                                    <p className="intro"
                                       style={{margin: "4px"}}>{`${data.name.replace(/^0+/, '')}번 개소: `}</p>
                                    <p className="intro"
                                       style={{margin: "4px"}}>{`${data.payload.temp_max} ${rate}`}</p>
                                    <p className="intro" style={{margin: "2px"}}>{`/`}</p>
                                    <p className="intro" style={{margin: "4px"}}>{`${data.payload.temp_avg} ${rate}`}</p>
                                    <p className="intro" style={{margin: "2px"}}>{`/`}</p>
                                    <p className="intro" style={{margin: "4px"}}>{`${data.payload.temp_min} ${rate}`}</p>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        );
    }
    return null;
};

const calculateStatusCounts = (transformedData) => {
    const statusTotalCounts = {위험: 0, 주의: 0, 경계: 0, 안전: 0};
    const statusCounts = {};

    transformedData.forEach(data => {
        if (data.status in statusTotalCounts) {
            statusTotalCounts[data.status] += 1;
        }

        if (!statusCounts[data.cameraCode]) {
            statusCounts[data.cameraCode] = {위험: 0, 주의: 0, 경계: 0, 안전: 0};
        }
        statusCounts[data.cameraCode][data.status] += 1;
    });
    return {statusTotalCounts, statusCounts};
};


const Sensor1HourChart = ({cameraData, setupValue }) => {
    const [ showLine, setShowLine ] = useState(false);
    const [ showDot, setShowDot ] = useState(false);
    const [ currentSlideIndex, setCurrentSlideIndex ] = useState(0);

    const transformedData = transformData(cameraData.currentValues, setupValue);
    const { statusTotalCounts, statusCounts } = calculateStatusCounts(transformedData);

    const dangerChartData = Object.keys(statusCounts).map(cameraCode => {
        return {
            name: `${parseInt(cameraCode, 10)}번 카메라`, // cameraCode를 "X번 개소" 형식으로 변환
            value: statusCounts[cameraCode]['위험'] // "위험" 상태의 개수
        };

    });

    const noticeChartData = Object.keys(statusCounts).map(cameraCode => {
        return {
            name: `${parseInt(cameraCode, 10)}번 카메라`, // cameraCode를 "X번 개소" 형식으로 변환
            value: statusCounts[cameraCode]['주의'] // "위험" 상태의 개수
        };
    });

    const alertChartData = Object.keys(statusCounts).map(cameraCode => {
        return {
            name: `${parseInt(cameraCode, 10)}번 카메라`, // cameraCode를 "X번 개소" 형식으로 변환
            value: statusCounts[cameraCode]['경계'] // "위험" 상태의 개수
        };
    });

    // 최소 및 최대 시간 계산을 위해 복원
    const times = transformedData.map(d => d.create_at);
    const minTime = Math.min(...times);
    const maxTime = Math.max(...times);

    return (
        <div className="column grid-container full-size-width" style={{position: "relative"}}>
            <div className="column grid-form" style={{position: "relative", margin: "0.5rem 1rem"}}>
                <div className="row grid-title">
                    <FontAwesomeIcon icon={faBarChart}/>
                    <div className="seperator-horz-half"></div>
                    <div className="row full-size">
                        <p className="bold grid-p">최근 1시간 데이터</p>
                        <div className="seperator-horz-half"></div>
                    </div>

                    <div className="row align-center">
                        <FontAwesomeIcon icon={faTemperatureHalf}/>
                        <p className="bold" style={{fontSize: 12, margin: '0 4px'}}>˚C</p>
                    </div>
                </div>
                <div className="row align-center full-size" style={{background: "#444444", boxSizing: "border-box"}}>
                    <div className="row align-center full-size" style={{boxSizing: "border-box", position: "relative", paddingTop: 8, paddingBottom: 8}}>
                        <div style={{ position: "relative", width: "auto", height: 200 }}>
                            <LineChart
                                width={750}
                                height={200}
                                data={transformedData}
                                margin={{ top: 5, right: 60, left: 8, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="1 3" />

                                <XAxis
                                    dataKey="create_at"
                                    domain={[minTime, maxTime]}
                                    tickFormatter={(unixTime) => moment(unixTime).format('DD일 HH:mm')}
                                    type="number"
                                    tick={{ fill: "#9d9d9d", fontSize: 10 }}
                                />
                                <YAxis yAxisId="left" orientation="left" domain={[35, 55]} tick={{ fill: "#9d9d9d", fontSize: 10 }}/>

                                <Tooltip content={<CustomTooltip />} />
                                <Legend
                                    iconSize={10}
                                    wrapperStyle={{
                                        fontSize: '10px',
                                    }}
                                    formatter={(value) => {
                                        const label = `${value}번 개소`;
                                        return label.replace(/^0+/, '');
                                    }}
                                />

                                {Object.keys(cameraData.currentValues).map((cameraCode, index) => (
                                    <Line
                                        yAxisId="left"
                                        type="monotone"
                                        dataKey="sensorValue"
                                        data={transformedData.filter(item => item.cameraCode === cameraCode)}
                                        stroke={colors.sensor1[index % colors.sensor1.length]}
                                        name={`${cameraCode}`}
                                        key={`${cameraCode}-${index}-${Math.random()}`}
                                        dot={showDot ? (props) => {
                                            const { payload } = props;
                                            if (!payload.status) return <></>;

                                            let fill;
                                            switch (payload.status) {
                                                case '위험':
                                                    fill = 'rgba(204, 0, 0, 0.8)';
                                                    break;
                                                case '주의':
                                                    fill = 'rgba(255, 215, 0, 0.8)';
                                                    break;
                                                case '경계':
                                                    fill = 'rgba(255, 165, 0, 0.8)';
                                                    break;
                                                default:
                                                    // 안전 또는 해당 없음 - 도트를 그리지 않음
                                                    return null;
                                            }

                                            return <circle cx={props.cx} cy={props.cy} r={3} fill={fill} />;
                                        } : false}
                                    />
                                ))}
                            </LineChart>
                        </div>
                        <div className="column" style={{position: "relative", width: 350, padding: 28, background: 'rgba(49,49,49,0.3)', borderRadius: "0.5rem"}}>
                            <div className="row" style={{position: "absolute", top: 16, left: 16}}>
                                <p className="bold" style={{fontSize: 12, margin: '0 4px'}}>카메라별 {currentSlideIndex === 0 ? '위험' : currentSlideIndex === 1 ? '주의' : '경계'} 비율</p>
                            </div>
                            <MobileCardSlider
                                onSlideChange={(current) => setCurrentSlideIndex(current)}
                            >
                                <div>
                                    {(dangerChartData.length === 0 || !dangerChartData.some(item => item.value !== 0)) ? (
                                        <div style={{width: 350, height: 200}}>
                                            <div className="column align-center" style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translateX(-50%) translateY(-50%)"
                                            }}>
                                                <FontAwesomeIcon style={{color: "#a8e367"}} size='4x'
                                                                 icon={faCheckCircle}/>
                                                <div className="row">
                                                            <span
                                                                style={{margin: "0.5rem 0 0.5rem 0", fontSize: 14}}>현재 추이가 <span
                                                                style={{color: "#a8e367", margin: 0, fontSize: 16}}>안전</span>합니다.</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <SimpleDoughnutChart statusCounts={dangerChartData}/>
                                    )}
                                </div>
                                <div>
                                    {(noticeChartData.length === 0 || !noticeChartData.some(item => item.value !== 0)) ? (
                                        <div style={{width: 350, height: 200}}>
                                            <div className="column align-center" style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translateX(-50%) translateY(-50%)"
                                            }}>
                                                <FontAwesomeIcon style={{color: "#a8e367"}} size='4x'
                                                                 icon={faCheckCircle}/>
                                                <div className="row">
                                                            <span
                                                                style={{margin: "0.5rem 0 0.5rem 0", fontSize: 14}}>현재 추이가 <span
                                                                style={{color: "#a8e367", margin: 0, fontSize: 16}}>안전</span>합니다.</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <SimpleDoughnutChart statusCounts={noticeChartData}/>
                                    )}
                                </div>
                                <div>
                                    {(alertChartData.length === 0 || !alertChartData.some(item => item.value !== 0)) ? (
                                        <div style={{width: 350, height: 200}}>
                                            <div className="column align-center" style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translateX(-50%) translateY(-50%)"
                                            }}>
                                                <FontAwesomeIcon style={{color: "#a8e367"}} size='4x'
                                                                 icon={faCheckCircle}/>
                                                <div className="row">
                                                            <span
                                                                style={{margin: "0.5rem 0 0.5rem 0", fontSize: 14}}>현재 추이가 <span
                                                                style={{color: "#a8e367", margin: 0, fontSize: 16}}>안전</span>합니다.</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <SimpleDoughnutChart statusCounts={alertChartData}/>
                                    )}
                                </div>
                            </MobileCardSlider>
                        </div>
                        <div className="row full-size-height" style={{margin: 16}}>
                            <div className="column full-size">
                                <div className="column full-size align-center">
                                    <p className="text-align-center" style={{margin: 4, width: 72, padding: 4, fontSize: 12, background: 'rgba(204, 0, 0, 0.8)', borderRadius: 16}}>위험</p>
                                    <p className="bold" style={{margin: 4}}>{statusTotalCounts['위험']}개</p>
                                </div>
                                <div className="column full-size align-center">
                                    <p className="text-align-center" style={{margin: 4, width: 72, padding: 4, fontSize: 12, background: 'rgba(255, 215, 0, 0.8)', borderRadius: 16}}>주의</p>
                                    <p className="bold" style={{margin: 4}}>{statusTotalCounts['주의']}개</p>
                                </div>
                                <div className="column full-size align-center">
                                    <p className="text-align-center" style={{margin: 4, width: 72, padding: 4, fontSize: 12, background: 'rgba(255, 165, 0, 0.8)', borderRadius: 16}}>경계</p>
                                    <p className="bold" style={{margin: 4}}>{statusTotalCounts['경계']}개</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Sensor1HourChart;
