// BaseAutocompleteInput.js
import React, {useState, useRef, useEffect} from 'react';
import '../styles/BaseAutocompleteInput.css';
import {tr} from "date-fns/locale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-regular-svg-icons";

function BaseAutocompleteInput({ data, name, placeholder, value, onChange, displayFunc, suggestionFunc, suggestionClick }) {
    const [inputValue, setInputValue] = useState(value || '');
    const [suggestions, setSuggestions] = useState(data);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [preventBlur, setPreventBlur] = useState(false);
    const inputValueRef = useRef(inputValue);

    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (!value) {
            setSuggestions(data.slice(0, 50));
        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleInputClick = (e) => {
        setInputValue('');

        setSuggestions(data.slice(0, 50));

        setIsFocused(true);
    };

    const handleMouseDown = () => {
        setPreventBlur(true);
    };

    const handleSuggestionClick = (suggestion) => {
        if(suggestionClick){
            suggestionClick(suggestion);
        }
        setInputValue(displayFunc(suggestion));
        setTimeout(() => handleBlurLogic(), 0);  // 직접 호출
        inputRef.current.blur();
    };

    const handleBlurLogic = () => {
        if (preventBlur) {
            setPreventBlur(false);
            return;
        }

        const companyNames = suggestions.map((item) => displayFunc(item));

        if (!companyNames.includes(inputValueRef.current)) {
            if(suggestionClick){
                suggestionClick(null);
            }
            setSuggestions([]);
            setInputValue('');
        }else{
            setSuggestions([suggestions.find(item => displayFunc(item) === inputValueRef.current)]);
        }

        setTimeout(() => setIsFocused(false), 50);
    };

    const handleBlur = () => {
        handleBlurLogic();
    };

    return (
        <div className="column full-size autocomplete-container">
            <div className="column full-size-width" style={{position: "relative"}}>
                <input
                    style={{paddingLeft: 36}}
                    ref={inputRef}
                    className="auto-input-base"
                    name={name}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleBlur}
                    onClick={handleInputClick}
                    autoComplete="off"
                />
                <FontAwesomeIcon icon={faSearch} color="#cccccc" size="xs" style={{position: "absolute", left: "1.5rem", top: "50%", transform: "translateY(-50%)"}}/>
            </div>

            {isFocused && (
                <ul>
                    {suggestions.length > 0 ? (
                        suggestions.map(item => (
                            <li key={displayFunc(item)} onMouseDown={handleMouseDown} onClick={() => handleSuggestionClick(item)}>
                                <div className="row full-size-width">
                                    <div className="row suggestion-item" style={{marginRight: "0.5rem"}}>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="row full-size-width align-center-left suggestion-item">
                                        <p>{item.node}번 카메라</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>결과가 없습니다.</li>
                    )}
                </ul>
            )}
        </div>
    );
}

function BaseAutocompleteInput2({ data, name, placeholder, value, onChange, displayFunc, suggestionFunc, suggestionClick, onFocus, onBlur }) {
    const [inputValue, setInputValue] = useState(value || '');
    const [suggestions, setSuggestions] = useState(data);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [preventBlur, setPreventBlur] = useState(false);
    const inputValueRef = useRef(inputValue);

    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (!value) {
            setSuggestions(data.slice(0, 50));
        } else {
            const newSuggestions = data
                .filter(option => suggestionFunc(option).toLowerCase().includes(value.toLowerCase())) // 수정된 부분
                .slice(0, 50);
            setSuggestions(newSuggestions);
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleInputClick = (e) => {
        setInputValue('');

        setSuggestions(data.slice(0, 50));

        setIsFocused(true);
    };

    const handleMouseDown = () => {
        setPreventBlur(true);
    };

    const handleSuggestionClick = (suggestion) => {
        if(suggestionClick){
            suggestionClick(suggestion);
        }
        setInputValue(displayFunc(suggestion));
        setTimeout(() => handleBlurLogic(), 0);
        inputRef.current.blur();
    };

    const handleBlurLogic = () => {
        if (preventBlur) {
            setPreventBlur(false);
            return;
        }

        const companyNames = suggestions.map((item) => displayFunc(item));
        if (!companyNames.includes(inputValueRef.current)) {
            if(suggestionClick){
                suggestionClick(null);
            }
            setSuggestions([]);
            setInputValue('');
        }else{
            setSuggestions([suggestions.find(item => displayFunc(item) === inputValueRef.current)]);
        }

        setTimeout(() => setIsFocused(false), 50);
        onBlur();
    };

    const handleBlur = () => {
        handleBlurLogic();
    };

    return (
        <div className="column full-size autocomplete-container" >
            <div className="column full-size-width" style={{position: "relative"}}>
                <input
                    style={{paddingLeft: 36}}
                    ref={inputRef}
                    className="auto-input-base"
                    name={name}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => {
                        setIsFocused(true)
                        onFocus();
                    }}
                    onBlur={handleBlur}
                    onClick={handleInputClick}
                    autoComplete="off"
                />
                <FontAwesomeIcon icon={faSearch} color="#cccccc" size="xs" style={{position: "absolute", left: "1.5rem", top: "50%", transform: "translateY(-50%)"}}/>
            </div>
            {isFocused && (
                <ul>
                    {suggestions.length > 0 ? (
                        suggestions.map((item, index) => (
                            <li key={displayFunc(item) + index} onMouseDown={handleMouseDown} onClick={() => handleSuggestionClick(item)}>
                                <div className="row full-size-width">
                                    <div className="row suggestion-item" style={{marginRight: "0.5rem"}}>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="row full-size-width align-center-left suggestion-item">
                                        <p>[{item.offset}]</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>결과가 없습니다.</li>
                    )}
                </ul>
            )}
        </div>
    );
}


export { BaseAutocompleteInput, BaseAutocompleteInput2 };
