import React, {useContext, useEffect, useRef, useState} from "react";
import '../styles/Main.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faEye, faEyeSlash, faLock, faSignIn, faUser} from "@fortawesome/free-solid-svg-icons";
import {BaseLoading} from "../BaseComponents/BaseLoading";
import {AppContext, backendServer} from "../App";
import {useNavigate} from "react-router-dom";

function SignIn() {
    const { setProfileImage, setIsDefaultImage} = useContext(AppContext);

    const navigate = useNavigate();

    const [values, setValues] = useState({
        id: "",
        pwd: ""
    });
    const [PWDType, setPWDType] = useState("password");
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alertText, setAlertText] = useState("");

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === "checkbox") {
            setKeepLoggedIn(checked);
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                // 서버에 로그인 상태 확인 요청 보내기
                const response = await fetch(`https://${backendServer}/check-login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.loggedIn) {
                        alert("이미 로그인 된 상태입니다.");
                        navigate("/");
                    }else{
                        setIsLoading(false)
                    }
                }
            } catch (error) {
                console.error("로그인 상태 확인 중 오류 발생");
            } finally {
                setIsLoading(false)
            }
        };

        checkLoginStatus();
    }, []);

    const handleSignIn = async () => {
        setIsLoading(true);
        setAlertText("");
        const idRegex = /^[A-Za-z0-9]+$/;

        if(values.id === ""){
            setAlertText("아이디를 입력해주세요.");
        } else if (!idRegex.test(values.id)) {
            setAlertText("아이디란에는 영어, 숫자만 입력 가능합니다.");
        } else if(values.pwd === ""){
            setAlertText("패스워드를 입력해주세요.");
        } else{
            try {
                const response = await fetch(`https://${backendServer}/signin`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({user_id: values.id, user_pwd: values.pwd, keepLoggedIn: keepLoggedIn}),
                    credentials: 'include'
                });

                if (response.ok) {
                    const pngUrl = `https://${backendServer}/profiles/profile_image_${values.id}.png`
                    const jpegUrl = `https://${backendServer}/profiles/profile_image_${values.id}.jpg`
                    const defaultImageUrl = process.env.PUBLIC_URL + '/images/free-icon-user-5264565.png';

                    const checkImage = (url) =>
                        new Promise((resolve) => {
                            const img = new Image();
                            img.onload = () => resolve(true);
                            img.onerror = () => resolve(false);
                            img.src = url;
                        });

                    const loadImage = async () => {
                        if (await checkImage(pngUrl)) {
                            setProfileImage(pngUrl);
                            setIsDefaultImage(false);
                        } else if (await checkImage(jpegUrl)) {
                            setProfileImage(jpegUrl);
                            setIsDefaultImage(false);
                        } else {
                            setProfileImage(defaultImageUrl);
                            setIsDefaultImage(true);
                        }
                    };

                    await loadImage();
                    navigate("/"); // 메인 페이지로 이동
                } else {
                    switch (response.status) {
                        case 401:
                            setAlertText("유저 정보가 올바르지 않습니다.");
                            break;
                        case 404:
                            setAlertText("유저 정보를 찾을 수 없습니다.");
                            break;
                        case 500:
                            setAlertText("현재 서버에 연결할 수 없습니다. 잠시 후 다시 시도해주세요.");
                            break;
                        default:
                            setAlertText("알 수 없는 에러가 발생했습니다.");
                            break;
                    }
                }
            } catch (error) {
                console.error("Fetch error");
                setAlertText("로그인 요청 중 에러가 발생했습니다.");
            } finally {
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    }

    return (
        <div className="column full-size background" style={{alignItems: "center"}}>
            {isLoading ? (
                <>
                    <BaseLoading/>
                </>
            ) : (
                <>
                    <div className="row full-size-width" style={{height: "5%", position: "relative", justifyContent: "center", borderBottom: "1px solid #ffffff50"}}>
                        <div className="row main-body full-size align-center" style={{padding: "0 1rem", boxSizing: "border-box", justifyContent: "center"}}>
                            <div className="full-size-width">
                                <p className="text-align-left bold" style={{fontSize: 18, color: "white"}}>지하차도 재난예보 모니터링</p>
                            </div>
                        </div>
                    </div>
                    <div className="column full-size-width align-center login-form">
                        <p style={{color: "white", fontSize: 30}}>로그인</p>
                        <div className="column full-size-width input-container">
                            <p style={{color: "rgba(255,63,63,0.5)", marginLeft: "0.5rem", fontSize: 12}}>{alertText}</p>
                            <div className="row input-container full-size-width align-center" style={{borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem", border: "1px solid #222222b0"}}>
                                <FontAwesomeIcon style={{color: "#808080", marginLeft: "1rem"}} fontSize={14} icon={faUser} />
                                <input autoComplete={"off"} name="id" onChange={handleChange} value={values.id} placeholder={"아이디"} className="full-size-width"/>
                            </div>
                            <div className="row input-container full-size-width align-center" style={{
                                borderBottomLeftRadius: "0.5rem",
                                borderBottomRightRadius: "0.5rem",
                                borderBottom: "1px solid #222222b0",
                                borderRight: "1px solid #222222b0",
                                borderLeft: "1px solid #222222b0"
                            }}>
                                <FontAwesomeIcon style={{color: "#808080", marginLeft: "1rem"}} fontSize={14} icon={faLock} />
                                <input autoComplete={"off"} name="pwd" onChange={handleChange} value={values.pwd} type={PWDType} placeholder={"패스워드"} className="full-size-width"/>
                                <FontAwesomeIcon style={{cursor: "pointer", color: "#FFFFFF", marginRight: "1rem"}} fontSize={16} icon={PWDType === 'password' ? faEye : faEyeSlash}
                                                 onClick={() => {
                                                     if(PWDType === 'password') {
                                                         setPWDType("text")
                                                     }else {
                                                         setPWDType("password")
                                                     }
                                                 }}/>
                            </div>
                            <div className="row input-container full-size-width align-center" style={{padding: "0.5rem"}}>
                                <input type="checkbox" id="keepLoggedIn" style={{display: "none"}} checked={keepLoggedIn} onChange={handleChange} className="custom-checkbox" />
                                <label htmlFor="keepLoggedIn" className="checkbox-label"></label>
                                <p className="full-size-width" style={{color: "white", fontSize: 12}}>로그인 유지</p>
                            </div>
                            <div className="row full-size-width align-center">
                                <button onClick={handleSignIn} className="login-button-base">로그인</button>
                            </div>
                            <div className="row full-size-width" style={{margin: "2rem 0", borderBottom: "1px solid #888888b0"}}/>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}

export default SignIn;
