import React, { useContext, useEffect, useState } from 'react';
import {
    faChevronDown,
    faChevronUp, faCircle, faCloud, faCloudMeatball, faCloudRain, faCloudShowersHeavy, faCloudSun, faDesktop,
    faFolder,
    faLocation, faLocationPinLock, faSnowflake, faSun, faTruckLoading
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles/CCTV.css';
import geojsonData from '../temp3.json';
import MobileCardSlider from "../BaseComponents/MobileCardSlider";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import { backendServer } from "../App";
import { GlobalContext } from "../page/Main";
import { ITEM_SIZE, widthPercentage } from "../tablerows/TableRow";
import { TableRow } from "../tablerows/TableRow";
import { FixedSizeList as List } from 'react-window';
import { faSensorAlert, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { BaseButton } from "../BaseComponents/BaseButton";

// 커스텀 툴팁 컴포넌트
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ fontSize: "14px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '10px' }}>
                <p className="label" style={{fontSize: 12, color: "black"}}>{payload[0].payload.Time}</p>
                <div className="column" style={{ fontSize: "10px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '5px' }}>
                    <div className="row align-center" >
                        <FontAwesomeIcon icon={faCircle} style={{ fontSize: "6px", color: payload[0].fill }}/>
                        <p className="intro" style={{ margin: "4px", color: "purple" }}>{`온도: `}</p>
                        <p className="intro" style={{ margin: "4px", color: "purple" }}>{payload[0].value} ˚C</p>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

// 실시간 온도 차트
function RealTimeTempLevelChart({ data }) {
    return (
        <div style={{ position: 'relative', marginTop: 30, width: "100%", height: 110 }}>
            <p style={{margin: "0 0 1rem 0", fontSize: 12}}>실시간 온도</p>
            <ResponsiveContainer width="100%" height={100} style={{display: "flex", borderRadius: "0.5rem", padding: "1rem 0", background: "#676767"}} className="align-center">
                <LineChart data={data} style={{display: "flex", right: "18px"}} className="align-center">
                    <XAxis stroke="#ffffff" dataKey="Time" tick={{ fontSize: 10 }} />
                    <YAxis
                        stroke="#ffffff"
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value, entry) => value.toFixed(1)}
                        domain={[23, 26]}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        iconType="circle"
                        iconSize={4}
                        wrapperStyle={{ fontSize: '12px', position: 'absolute', right: "114px"}}
                        align="right"
                        formatter={(value, entry) => <span style={{ color: entry.color }}>온도</span>}
                    />
                    <Line
                        type="monotone"
                        dataKey="Water Level"
                        stroke="#8884d8"
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

// 최근 1시간 온도 차트
function TempChart1Hour({ data }) {
    const maxWaterLevel = Math.max(...data.map(item => item["Temp"]));
    const minWaterLevel = Math.min(...data.map(item => item["Temp"]));

    return (
        <div style={{ marginTop: 8, width: "100%", height: 110 }}>
            <p style={{margin: "0 0 0.5rem 0.5rem", fontSize: 12}}>최근 1시간 데이터</p>
            <ResponsiveContainer width="100%" height={100} style={{display: "flex", borderRadius: "0.5rem", padding: "1rem 0", background: "#676767"}} className="align-center">
                <LineChart data={data} style={{display: "flex", right: "18px"}} className="align-center">
                    <XAxis stroke="#ffffff80" dataKey="Time" tick={{ fontSize: 8 }} />
                    <YAxis
                        stroke="#ffffff80"
                        tick={{ fontSize: 8 }}
                        tickFormatter={(value) => value.toFixed(1)}
                        domain={[minWaterLevel * 0.8, maxWaterLevel * 1.2]}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                        type="monotone"
                        dataKey="Temp"
                        stroke="#8884d8"
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

// 더미 데이터 생성 함수
function generateDummyData() {
    const data = [];
    let currentTime = new Date();

    for (let i = 0; i < 60; i++) {
        const time = new Date(currentTime.getTime() - i * 60000);
        const temp = Math.random() * (25 - 24) + 24;
        data.push({
            Time: time.getHours() + ':' + time.getMinutes().toString().padStart(2, '0'),
            "Water Level": temp.toFixed(1)
        });
    }

    return data.reverse();
}

// 또 다른 더미 데이터 생성 함수
function generateDummyData2() {
    const data = [];
    let currentTime = new Date();

    for (let i = 0; i < 60; i++) {
        const time = new Date(currentTime.getTime() - 3600000 + i * 60000);
        let temp;
        if (i < 48) {
            temp = Math.random() * (25.1 - 24.2) + 24.2;
        } else {
            const totalRise = 57.4 - 25.1;
            const exponentialFactor = (i - 48) / (59 - 48);
            temp = 25.1 + totalRise * (1 - Math.exp(-3 * exponentialFactor));
        }
        data.push({
            Time: time.getHours() + ':' + time.getMinutes().toString().padStart(2, '0'),
            Temp: temp.toFixed(1)
        });
    }

    return data;
}

// 더미 데이터 샘플
const sampleData = generateDummyData();
const sampleData2 = generateDummyData2();

// TreeItem 컴포넌트: 위치 데이터를 트리 구조로 표시하는 컴포넌트
const TreeItem = ({ item, onToggle, isOpen, selectedLocation, onMapData }) => {
    const hasChildren = item.children && item.children.length > 0;

    // 트리 항목 확장/축소 핸들러
    const handleToggle = (location, e) => {
        e.stopPropagation();
        const path = item.path || item.name;
        onToggle(path);
    };

    // 트리 항목 클릭 핸들러
    const handleClick = (location, e) => {
        e.stopPropagation();
        if (selectedLocation.name !== location.name) {
            onMapData(location);
        }
    };

    return (
        <>
            <div className="row tree-item" style={{alignItems: "center", backgroundColor: selectedLocation.name === item.name ? '#666' : ''}} onClick={handleClick.bind(null, item)}>
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                <p style={{margin: 0}}>{item.name}</p>

                {hasChildren && (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp} onClick={handleToggle.bind(null, item)}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onMapData={onMapData}
                            selectedLocation={selectedLocation}
                            isOpen={isOpen}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

// Home 컴포넌트: 주요 지도 및 CCTV 관리 화면
function Home() {
    const { weatherData } = useContext(GlobalContext);
    const locationData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            dx: 36.2002,
            dy: 127.054,
            level: 12,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    dx: 35.061480,
                    dy: 126.988566,
                    level: 11,
                    children: [
                        {
                            isRoot: false,
                            childIcon: faLocation,
                            name: "KT광주타워",
                            nx: 59,
                            ny: 76,
                            dx: 35.1463815,
                            dy: 126.9235669,
                            locationCode: '001',
                            level: 2,
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "전라북도",
                    dx: 35.842969, // 충청북도 청주 좌표
                    dy: 127.149597,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "충청북도",
                    dx: 36.9910113,
                    dy: 127.9259497,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "충청남도",
                    dx: 36.7058118, // 충청북도 청주 좌표
                    dy: 126.6594254,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경상북도",
                    dx: 36.5683543,
                    dy: 128.729357,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경상남도",
                    dx: 35.56361667, // 경상북도 안동 좌표
                    dy: 128.1679306,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "강원특별자치도",
                    dx: 37.69442222, // 강원도 춘천 좌표
                    dy: 127.8908417,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경기도",
                    dx: 37.48893611, // 경기도 수원 좌표
                    dy: 127.4898861,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "제주특별자치도",
                    dx: 33.2141, // 강원도 춘천 좌표
                    dy: 126.3148,
                    level: 10,
                    childIcon: faLocation,
                },
            ]
        },
    ];

    const [logging] = useState([
        { type: 1, msg: "69˚C", time: "2024-05-02 17:31:26" },
        { type: 1, msg: "57˚C", time: "2024-05-01 14:49:28" },
        { type: 0, msg: "39˚C", time: "2024-04-30 12:33:58" },
        { type: 0, msg: "41˚C", time: "2024-04-27 15:12:33" }
    ]);
    const [isPopup, setIsPopup] = useState(true);

    const [selectedLocation, setSelectedLocation] = useState(locationData[0]);
    const [weatherIcon, setWeatherIcon] = useState(faTruckLoading);
    const [temp, setTemp] = useState(0);
    const [pop, setPop] = useState(0);
    const [weatherText, setWeatherText] = useState("");

    // 트리 항목 초기화 함수
    const initializeOpenTreeItems = (data, path = '') => {
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            acc[currentPath] = false;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(locationData));

    // 트리 항목 확장/축소 핸들러
    const toggleTreeItem = (path) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: !prevOpenTreeItems[path],
        }));
    };

    // 지도에 폴리곤 그리기
    const drawPolygonFromGeoJSON = (map, geojsonData, name) => {
        geojsonData.features.forEach((feature) => {
            if (feature.properties.CTP_KOR_NM === name) {
                const coordinates = feature.geometry.coordinates[0].map(([lng, lat]) => new window.kakao.maps.LatLng(lat, lng));

                const polygon = new window.kakao.maps.Polygon({
                    path: coordinates,
                    strokeWeight: 2,
                    strokeColor: '#ff3232',
                    strokeOpacity: 1,
                    fillColor: '#ff3232',
                    fillOpacity: 0.15
                });

                polygon.setMap(map);
            }
        });
    };

    // 지도에 마커 그리기
    const drawMarker = (map, children) => {
        children.forEach((child) => {
            const markerPosition  = new window.kakao.maps.LatLng(child.dx, child.dy);
            const marker = new window.kakao.maps.Marker({
                position: markerPosition,
                title: child.name
            });
            marker.setMap(map);

            const iwContent = `<div style="width: 100%; padding:5px; color: black; white-space: nowrap; text-align: center;">${child.name}</div>`;

            const infowindow = new window.kakao.maps.InfoWindow({
                content : iwContent
            });

            window.kakao.maps.event.addListener(marker, 'mouseover', function() {
                infowindow.open(map, marker);
            });

            window.kakao.maps.event.addListener(marker, 'mouseout', function() {
                infowindow.close();
            });

            if (!child.isRoot) {
                window.kakao.maps.event.addListener(marker, 'click', function() {
                    getMapData(child);
                });
            }
        });
    };

    // 지도 데이터 가져오기
    const getMapData = (location) => {
        const { dx, dy, level, name } = location;
        if (window.kakao) {
            const container = document.getElementById('map');
            const options = {
                center: new window.kakao.maps.LatLng(dx, dy),
                level: level
            };
            const map = new window.kakao.maps.Map(container, options);

            if (name !== '대한민국') {
                drawPolygonFromGeoJSON(map, geojsonData, name);

                if (location.children && location.children.length > 0) {
                    drawMarker(map, location.children);
                } else if (!location.isRoot) {
                    drawMarker(map, [location]);
                }
            }

            map.setZoomable(false);
            setSelectedLocation(location);
        }
    };

    useEffect(() => {
        getMapData(selectedLocation);
    }, []);

    // 날씨 데이터 변경 시 아이콘 및 텍스트 업데이트
    useEffect(() => {
        if (weatherData && weatherData.today) {
            const now = new Date();
            const kstOffset = 9 * 60 * 60 * 1000;
            const kstDate = new Date(now.getTime() + kstOffset);
            const currentHour = kstDate.getHours();
            const todayItems = weatherData.today.filter(item => item.fcstTime === (String(currentHour).padStart(2, '0') + '00'));

            todayItems.forEach(item => {
                switch (item.category) {
                    case 'TMP':
                        setTemp(item.fcstValue);
                        break;
                    case 'SKY':
                        if (item.fcstValue === "1") {
                            setWeatherIcon(faSun);
                            setWeatherText("맑음");
                        } else if (item.fcstValue === "3") {
                            setWeatherIcon(faCloudSun);
                            setWeatherText("구름");
                        } else if (item.fcstValue === "4") {
                            setWeatherIcon(faCloud);
                            setWeatherText("흐림");
                        }
                        break;
                    case 'PTY':
                        if (item.fcstValue === "1") {
                            setWeatherIcon(faCloudShowersHeavy);
                            setWeatherText("비");
                        } else if (item.fcstValue === "2") {
                            setWeatherIcon(faCloudMeatball);
                            setWeatherText("비와 눈");
                        } else if (item.fcstValue === "3") {
                            setWeatherIcon(faSnowflake);
                            setWeatherText("눈");
                        } else if (item.fcstValue === "4") {
                            setWeatherIcon(faCloudRain);
                            setWeatherText("소나기");
                        }
                        break;
                    case 'POP':
                        setPop(item.fcstValue);
                        break;
                }
            });
        }
    }, [weatherData]);

    const LocationTree = ({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onMapData={getMapData}
                        selectedLocation={selectedLocation}
                        isOpen={openTreeItems}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="column full-size">
            {/* 상단 메뉴 */}
            <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                <FontAwesomeIcon size="xs" style={{margin: 8}} icon={faDesktop} />
                <p className="bold" style={{width: "calc(80% + 32px)", margin: "8px 8px 8px 0px", fontSize: 13, color: "white"}}>통합 관제 모니터</p>
                <p className="bold" style={{width: "20%",marginLeft: 16, fontSize: 13, color: "white"}}>지역별</p>
            </div>
            {/* 메인 화면 */}
            <div className="row full-size-width" style={{height: "97%", position: "relative"}}>
                {/* 지도 및 세부 데이터 */}
                <div className="column full-size" style={{width: "80%", position: "relative", overflow: 'hidden'}}>
                    <div id="map" style={{width: '100%', height: '100%'}}></div>
                    {selectedLocation && !selectedLocation.isRoot && (
                        <div className="column" style={{
                            position: "absolute",
                            width: "25%",
                            height: "90%",
                            right: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderRadius: "1rem",
                            background: "#4f4f4f",
                            zIndex: 10,
                        }}>
                            {/* 지역 정보 */}
                            <div className="column" style={{
                                background: "#cccccc",
                                borderTopLeftRadius: "1rem",
                                borderTopRightRadius: "1rem"
                            }}>
                                <div className="row align-center-left" style={{padding: "0.5rem 1rem"}}>
                                    <FontAwesomeIcon style={{color: "black"}} icon={faLocationPinLock}/>
                                    <p style={{
                                        margin: "0 0.5rem",
                                        color: "black",
                                        fontWeight: "bold"
                                    }}>{selectedLocation.name}</p>
                                </div>
                            </div>
                            {/* 실시간 정보 */}
                            <div className="column" style={{marginTop: "1rem", width: "100%", padding: "0 0.5rem 0.5rem 0.5rem", boxSizing: "border-box"}}>
                                <p style={{margin: "0 0 1rem 0", fontSize: 12}}>실시간 현황</p>
                                <MobileCardSlider>
                                    <div style={{height: "10px"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/001/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                    <div style={{height: "10%"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/002/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                </MobileCardSlider>
                                <div className="seperator"/>
                                <MobileCardSlider>
                                    <div style={{height: "10px"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/003/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                    <div style={{height: "10%"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/004/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                </MobileCardSlider>
                                <RealTimeTempLevelChart data={sampleData}/>
                                {/* 기록 테이블 */}
                                <div className="column full-size-width align-center" style={{margin: "0", marginTop: "0.2rem"}}>
                                    <div className="column main-table-body full-size-width">
                                        <div className="table-container-h1">
                                            <div className="column table-body-container" style={{height: 100, marginTop: "0.5rem"}}>
                                                <div className="row full-size-width table-header" style={{height: 20, overflowY: "scroll"}}>
                                                    <div className="column align-center" style={{
                                                        flex: widthPercentage[0],
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        borderRight: '1px solid #2B2B2B'
                                                    }}>
                                                        <p className="text-align-center" style={{fontSize: 10}}>구분</p>
                                                    </div>
                                                    <div className="column align-center" style={{
                                                        flex: widthPercentage[2],
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        borderRight: '1px solid #2B2B2B'
                                                    }}>
                                                        <p className="text-align-center" style={{fontSize: 10}}>온도</p>
                                                    </div>
                                                    <div className="column align-center" style={{
                                                        flex: widthPercentage[7],
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                        <p className="text-align-center" style={{fontSize: 10}}>시간</p>
                                                    </div>
                                                </div>
                                                <List
                                                    height={100}
                                                    className="table full-size-width"
                                                    itemCount={logging.length}
                                                    itemSize={ITEM_SIZE}
                                                    itemData={logging}
                                                    style={{overflowX: 'hidden'}}
                                                >
                                                    {TableRow}
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* 팝업 */}
                    {isPopup && (
                        <div className="column" style={{
                            position: "absolute",
                            width: "35%",
                            height: "auto",
                            left: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderRadius: "1rem",
                            background: "#4f4f4f",
                            zIndex: 10,
                        }}>
                            <div className="column"
                                 style={{
                                     background: "#cccccc",
                                     borderTopLeftRadius: "1rem",
                                     borderTopRightRadius: "1rem"
                                 }}>
                                <div className="row align-center-left" style={{padding: "0.5rem 1rem"}}>
                                    <FontAwesomeIcon style={{color: "black"}} icon={faSensorAlert}/>
                                    <p className="row full-size-width" style={{
                                        margin: "0 0.5rem",
                                        color: "black",
                                        fontSize: 14
                                    }}>KT광주타워<p
                                        style={{margin: 0, padding: 0, fontWeight: "bold", color: "red"}}>[위험]</p></p>
                                    <FontAwesomeIcon style={{color: "black", cursor: "pointer"}} icon={faTimes} onClick={() => setIsPopup(false)}/>
                                </div>
                            </div>
                            <div className="seperator-half"/>
                            <div className="column"
                                 style={{width: "100%", padding: "0.5rem", boxSizing: "border-box"}}>
                                <div className="row align-center full-size-width">
                                    <div style={{margin: "0.3rem"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/001/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                    <div style={{margin: "0.3rem"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/101/003/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem",
                                                width: "100%",
                                                height: 150
                                            }}/>
                                    </div>
                                </div>
                                <div className="column" style={{width: "100%", boxSizing: "border-box"}}>
                                    <TempChart1Hour data={sampleData2}/>
                                </div>
                                <div className="row align-center full-size-width" style={{marginTop: 64}}>
                                    <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}} onClick={
                                        () => {
                                            setOpenTreeItems(prevOpenTreeItems => ({
                                                ...prevOpenTreeItems,
                                                "대한민국": true,
                                                "대한민국/전라남도": true,
                                            }));
                                            getMapData({
                                                isRoot: false,
                                                childIcon: faLocation,
                                                name: "KT광주타워",
                                                nx: 59,
                                                ny: 76,
                                                dx: 35.1463815,
                                                dy: 126.9235669,
                                                locationCode: '001',
                                                level: 2,
                                            });
                                        }
                                    }>위치로 이동</BaseButton>
                                    <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}} onClick={() => setIsPopup(false)}>닫기</BaseButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* 지역 트리 */}
                <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                    <LocationTree data={locationData}/>
                </div>
            </div>
        </div>
    );
}

export default Home;
